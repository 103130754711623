import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../auth/ProtectedRoutes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import EmailVerification from '../auth/EmailVerification';
import Tooltip from '@mui/material/Tooltip';
import { getQueuePrioritization, getMaxImages, getMonthlyImpressions, getPrice, getVideoLinkAllowed, getStripePriceID, isUpgrade } from '../../utility/utilityFunctions';
import { useStartStripeSessionMutation, useCancelSubscriptionMutation, useChangeSubscriptionMutation } from './usersApiSlice'
import Swal from 'sweetalert2';

const SelectPlan = () => {
    const user = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const {
        email,
        plan: currentPlan,
        cancelDate: cancelDateString,
        changeDate: changeDateString,
        changePlan,
        active: isActive
    } = user || {};

    const [isWrapped, setIsWrapped] = useState(false);
    const [isTiny, setIsTiny] = useState(false);
    const [startStripeSession] = useStartStripeSessionMutation();
    const [cancelSubscription] = useCancelSubscriptionMutation();
    const [changeSubscription] = useChangeSubscriptionMutation();

    const environment = process.env.NODE_ENV || 'development';
    const isProduction = environment !== 'development';
   
    const unavailablePlans = ["Starter", "Influencer", "Professional", "Premium"]

    const createStripeSession = async (plan) => {
        setErrorMessage("");
        setIsProcessing(true);

        const priceID = getStripePriceID(plan, isProduction)
        // Bedeutet, wir haben Free Plan angeklickt
        if (!priceID) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'This will cancel your plan at the end of the current billing period.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, switch to free plan',
                cancelButtonText: 'No, keep my plan'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const result = await cancelSubscription({ doCancel: true });

                        if (!result.error) {
                            setTimeout(() => {
                                navigate("/dash/user")
                                window.location.reload()
                                setIsProcessing(false)
                            }, 2000);
                        }
                        else {
                            setErrorMessage(result.error.data.message);
                            setIsProcessing(false);
                        }
                    }
                    catch (err) {
                        setErrorMessage("There was a server problem. Please try again later.");
                        setIsProcessing(false);
                    }
                    return
                }
                else {
                    setIsProcessing(false);
                    return
                }
            });
        }
        else if (plan === currentPlan && cancelDateString) {
            Swal.fire({
                title: 'Please confirm',
                text: 'This will stop your previously requested cancelation and reactivate your current plan.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Reactivate my plan',
                cancelButtonText: 'Keep my plan canceled'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const result = await cancelSubscription({ doCancel: false });

                        if (!result.error) {
                            setTimeout(() => {
                                navigate("/dash/user")
                                window.location.reload()
                                setIsProcessing(false)
                            }, 2000);
                        }
                        else {
                            setErrorMessage(result.error.data.message);
                            setIsProcessing(false)
                        }
                    }
                    catch (err) {
                        setErrorMessage("There was a server problem. Please try again later.");
                        setIsProcessing(false)
                    }
                    return
                }
                else {
                    setIsProcessing(false);
                    return
                }
            });
        }
        else if (plan === currentPlan && changeDateString) {
            Swal.fire({
                title: 'Please confirm',
                text: 'This will stop your previously scheduled plan change and reactivate your current plan.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Reactivate my plan',
                cancelButtonText: 'Keep my scheduled plan change'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const result = await changeSubscription({ plan, immediate: true });

                        if (!result.error) {
                            setTimeout(() => {
                                navigate("/dash/user")
                                window.location.reload()
                                setIsProcessing(false)
                            }, 2000);
                        }
                        else {
                            setErrorMessage(result.error.data.message);
                            setIsProcessing(false)
                        }
                    }
                    catch (err) {
                        setErrorMessage("There was a server problem. Please try again later.");
                        setIsProcessing(false)
                    }
                    return
                }
                else {
                    setIsProcessing(false);
                    return
                }
            });
        }
        else if (currentPlan === "Free") {
            try {
                const result = await startStripeSession({ plan });

                if (!result.error) {
                    const url = result.data.url
                    window.location.href = url
                }
                else {
                    setErrorMessage(result.error.data.message);
                }
            }
            catch (err) {
                setErrorMessage("There was a server problem. Please try again later.");
            }
            setIsProcessing(false);
            return
        }
        else {
            if (isUpgrade(currentPlan, plan)) {
                Swal.fire({
                    title: "Confirm Your Plan Upgrade",
                    html: `Please note that upgrading your plan will take effect based on your selection below.<br><br>
                    Upon confirmation, you will receive all the impression credits and benefits associated with the new plan right away or at the beginning of the next billing cycle.<br><br>
                    <strong>Charges for the new plan will apply:</strong><br>
                    - Immediately, if you choose to upgrade now.<br>
                    - At the beginning of the next billing cycle, if you choose to upgrade later.`,
                    icon: 'warning',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade Now',
                    cancelButtonText: 'Keep my plan',
                    showDenyButton: true,
                    denyButtonText: 'Upgrade at Next Billing Cycle'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await handleUpgrade({ plan, immediate: true });
                    } else if (result.isDenied) {
                        await handleUpgrade({ plan, immediate: false });
                    } else {
                        setIsProcessing(false);
                    }
                });

                const handleUpgrade = async (params) => {
                    try {
                        const result = await changeSubscription(params);

                        if (!result.error) {
                            setTimeout(() => {
                                navigate("/dash/user");
                                window.location.reload();
                                setIsProcessing(false);
                            }, 2000);
                        } else {
                            setErrorMessage(result.error.data.message);
                            setIsProcessing(false);
                        }
                    } catch (err) {
                        setErrorMessage("There was a server problem. Please try again later.");
                        setIsProcessing(false);
                    }
                };
            }
            else {
                Swal.fire({
                    title: "Confirm Your Plan Downgrade",
                    text: "Downgrading your plan will take effect at the beginning of the next billing cycle. Do you wish to proceed?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, downgrade my plan',
                    cancelButtonText: 'No, keep my plan'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const result = await changeSubscription({ plan, immediate: false });

                            if (!result.error) {
                                setTimeout(() => {
                                    navigate("/dash/user")
                                    window.location.reload()
                                    setIsProcessing(false)
                                }, 2000);
                            }
                            else {
                                setErrorMessage(result.error.data.message);
                                setIsProcessing(false)
                            }
                        }
                        catch (err) {
                            setErrorMessage("There was a server problem. Please try again later.");
                            setIsProcessing(false)
                        }
                        return
                    }
                    else {
                        setIsProcessing(false);
                        return
                    }
                });
            }
        }
    }

    const renderPlans = (start, end) => (
        plans.slice(start, end).map((plan, planIndex) => {
            let buttonLabel;
            let isDisabled;

            if (unavailablePlans.includes(plan)) {
                buttonLabel = 'N/A';
                isDisabled = true;
            }
            else if (cancelDateString) {
                if (plan === "Free") {
                    buttonLabel = "Current"
                    isDisabled = true;
                }
                else if (plan === currentPlan) {
                    buttonLabel = "Reactivate"
                    isDisabled = false;
                }
                else {
                    buttonLabel = "Select";
                    isDisabled = false;
                }
            }
            else if (changeDateString) {
                if (plan === changePlan) {
                    buttonLabel = "Current"
                    isDisabled = true;
                }
                else if (plan === currentPlan) {
                    buttonLabel = "Reactivate"
                    isDisabled = false;
                }
                else {
                    buttonLabel = "Select";
                    isDisabled = false;
                }
            }
            else {
                if (plan === currentPlan) {
                    buttonLabel = "Current"
                    isDisabled = true;
                }
                else {
                    buttonLabel = "Select";
                    isDisabled = false;
                }
            }

            return (
                <div className="plan-table" key={plan}>
                    <table className="subscription-table">
                        <thead>
                            <tr>
                                <th>{plan}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {features.map((feature, index) => (
                                <tr key={feature}>
                                    <td>{featureFunctions[index](plan)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    {formattedPrice(plan)}
                                    {isProcessing ? (
                                        <div className="spinner-wrapper">
                                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                        </div>
                                    ) : (
                                        <button
                                            disabled={isDisabled}
                                            className="select-plan-btn" onClick={() => createStripeSession(plan)}
                                        >
                                            {buttonLabel}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            );
        })
    );

    const handleResize = () => {
        //setIsWrapped(window.innerWidth <= 750);
        setIsWrapped(false);
        setIsTiny(window.innerWidth <= 360);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Call on component mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const backToAccountDetails = () => {
        navigate("/dash/user")
    }

    const allowsVideoLink = (plan) => {
        return getVideoLinkAllowed(plan) ? "Yes" : "No";
    }

    const formattedImpressions = (plan) => {
        const imp = getMonthlyImpressions(plan);
        return `${imp.toLocaleString()}`
    }

    const formattedPrio = (plan) => {
        const prio = getQueuePrioritization(plan);
        return `${prio}x`
    }

    const formattedPrice = (plan) => {
        let content = (
            <>
                <span className="standout">Free</span>
            </>
        )
        if (plan !== "Free") {
            content = (
                <>
                    <span className="standout">${(getPrice(plan) / 100).toFixed(2)}</span>
                    <span style={{ fontSize: '80%' }}> /mo</span>
                </>
            );
        }

        return (<div style={{ whiteSpace: 'nowrap' }}>{content}</div>)
    };

    let content;

    // KW_Attention Wenn wir mehr als drei Plaene freischalten, muss oben das setIsWrapped dynamisch je nach Fensterbreite gesetzt werden (die auskommentierte Zeile dort verwenden), nicht fix auf setIsWrapped(false)!
    //const plans = ['Free', 'Starter', 'Influencer', 'Professional', 'Premium'];
    const plans = ['Free', 'Starter', 'Influencer'];
    const features = ['Impressions', 'Prioritization', 'Max Images', 'Video Links'];
    const tooltips = ["Impression Credits determine how often your thumbnails are shown to other users. Subscription plans award you a certain amount of impression credits each month (topups are capped at 120%).", "Queue prioritization is automatically applied to all of your thumbnails. It speeds up data gathering by the factor shown here.", "How many thumbnails you can have online at the same time.", "If you specify a Youtube video link on your thumbnail's detail page, other users get the option of watching the video after they voted for your thumbnail."]
    const featuresAbbrev = ['Impres-sions', 'Prioriti-zation', 'Max Images', 'Video Links'];
    const featureFunctions = [formattedImpressions, formattedPrio, getMaxImages, allowsVideoLink];
    const featuresToUse = isTiny ? featuresAbbrev : features;

    content = (
        <>
            <div>
                <h3 className="headline">Select Plan</h3>

                {isActive ? (
                    <>
                        <div className="subscription-tables-container">
                            <div className="feature-table">
                                <table className="subscription-table">
                                    <thead>
                                        <tr>
                                            <th>Feature</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {featuresToUse.map((feature, index) => (
                                            <tr key={feature}>
                                                <Tooltip title={tooltips[index]} arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                                    <td>{feature}</td>
                                                </Tooltip>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Price</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            {renderPlans(0, 3)}
                            {!isWrapped && renderPlans(3, 5)}
                        </div>

                        {isWrapped && (
                            <div className="subscription-tables-container wrapped">
                                <div className="feature-table">
                                    <table className="subscription-table">
                                        <thead>
                                            <tr>
                                                <th>Feature</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {features.map((feature, index) => (
                                                <tr key={feature}>
                                                    <Tooltip title={tooltips[index]} arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                                        <td>{feature}</td>
                                                    </Tooltip>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>Price</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                {renderPlans(3, 5)}
                            </div>
                        )}

                        <div style={{ width: "100%", textAlign: "center", fontSize: "95%" }}><p>Paid plans renew monthly, you can unsubscribe at any time.</p></div>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}

                        <div className="nav__buttons_bottom">
                            <button className="button" onClick={backToAccountDetails}>Back</button>
                        </div></>) :
                    null
                }
            </div>

            {!isActive && <EmailVerification email={email} isActive={isActive} />}
        </>
    );

    return (
        <>
            {content}
        </>
    );
};


// Monthly impressions (relieves you from having to rate other users' thumbnails to earn impressions for your own thumbnails)


export default SelectPlan;
