import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getImageURL } from "../../utility/utilityFunctions";

const ImgRating = ({ img, onImageClick }) => {
    if (img) {
        const imagePath = getImageURL(img);

        return (
            <div className="rating-item">
                {imagePath ? (
                    <div className="rating-img-wrapper" onClick={() => onImageClick(img.id)}>
                        <img className="clickable-image" src={imagePath} alt="Thumbnail" />
                    </div>
                ) : (
                    <div className="spinner-wrapper">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    </div>
                )}

                <p className="rating-videotitle">{img.videotitle === "" || img.videotitle === "N/A" ? "No title" : img.videotitle}</p>
            </div>
        );
    } else return null;

};
export default ImgRating;