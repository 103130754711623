import React, { useEffect, useState } from 'react'

function useTargetImpressions(targetImpressions, setTargetImpressions, impressions) {
    useEffect(() => {
        setTargetImpressions(targetImpressions);
    }, [targetImpressions]);

    const onTargetImpressionsChanged = (e) => {
        if (e.target.value <= impressions) {
            setTargetImpressions(parseInt(e.target.value));
        }
        else {
            setTargetImpressions(parseInt(e.target.value));
        }
    };

    const reset = () => {
        //console.log("Target Impressions Reset to:", targetImpressions)
        setTargetImpressions(targetImpressions);
    };

    return { onTargetImpressionsChanged, reset };
}

export default useTargetImpressions