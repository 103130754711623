import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditEmailMutation } from '../users/usersApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setEmailVerificationError } from '../auth/authSlice';
import { useSendVerificationEmailMutation } from '../auth/authApiSlice';
import { useGetCurrentUserQuery } from './usersApiSlice';
import validator from 'validator';

const EditUserEmail = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailEdited, setEmailEdited] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [editEmail] = useEditEmailMutation();
    const [sendVerificationEmail] = useSendVerificationEmailMutation();

    const handlePasswordInput = (e) => setPassword(e.target.value);
    const handleEmailInput = (e) => setEmail(e.target.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { refetch: refetchUser } = useGetCurrentUserQuery();

    const handleEditEmail = async (e) => {
        e.preventDefault();

        if (!password) {
            setErrorMessage('Please enter your current password.');
            return;
        }

        if (!email) {
            setErrorMessage('Please enter your new email address.');
            return;
        }

        if (!validator.isEmail(email)) {
            setErrorMessage('Invalid email format.');
            return;
        }

        setErrorMessage("");
        setIsProcessing(true);

        try {
            const result = await editEmail({ email, currentPassword: password });

            if (!result.error) {
                try {
                    await sendVerificationEmail(email).unwrap();
                    dispatch(setEmailVerificationError(undefined));
                } catch (err) {
                    dispatch(setEmailVerificationError('There was an issue sending the verification email. Please make sure you have your email address entered correctly.'));
                }

                // Email change successful
                setEmailEdited(true);

                refetchUser();
                navigate('/dash/user');
            } else {
                setErrorMessage(result.error.data.message);
            }
        } catch (err) {
            setErrorMessage("There was an issue setting your new email. Please try again later.");
        }
        setIsProcessing(false);
    };

    const backToAccountDetails = () => {
        navigate("/dash/user")
    }

    return (
        <>
            <div className="darkish-box">
                <div className="standard-container-centered" style={{ paddingBottom: "0px" }}>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Edit Email Address</h2>
                    </div>
                </div>
                <div className="standard-container-centered" style={{ marginTop: "0px", paddingTop: "30px" }}>
                    {!emailEdited ? (
                        <form className="form-login" onSubmit={e => e.preventDefault()}>

                            <label htmlFor="email" className="form-login__label">New Email:</label>
                            <input
                                className="form-login__input"
                                type="email"
                                id="email"
                                value={email}
                                onChange={handleEmailInput}
                                autoComplete="off"
                                required
                            />

                            <label htmlFor="password" className="form-login__label">Current Password:</label>
                            <input
                                className="form-login__input"
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordInput}
                                autoComplete="off"
                                required
                            />

                            {isProcessing ? (
                                <div className="spinner-wrapper">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            ) : (
                                <div className="nav__buttons_bottom">
                                    <button className="button" onClick={backToAccountDetails} disabled={isProcessing}>Back</button>
                                    <button className="button" onClick={handleEditEmail} disabled={isProcessing}>Apply New Email Address</button>
                                </div>)}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </form>
                    ) : (
                        <div>
                            <p>Your email has been successfully changed.</p>
                            <div className="nav__buttons_bottom">
                                <button className="button" onClick={backToAccountDetails} disabled={isProcessing}>Account Details</button>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    )
}

export default EditUserEmail;
