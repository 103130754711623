import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsofService = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div>
                <h2 className="policy-headline">Terms of Service</h2>
                <p>Last updated: March 17, 2024</p>

                <h2 className="policy-headline">1. Acceptance of Terms</h2>
                <p>By accessing or using ThumbRater ("we," "us," "our"), you agree to be bound by these Terms of Service. If you disagree with any part of these terms, please do not use our service.</p>

                <h2 className="policy-headline">2. Registration</h2>
                <p>To use our service, you must register for an account. You agree to provide an accurate and current email address during the registration process and to update the address to keep it accurate and current.</p>

                <h2 className="policy-headline">3. Content Guidelines</h2>
                <p><strong>No Nudity or Sexually Explicit Content</strong></p>
                <p><strong>No Extreme Violence or Gore</strong></p>
                <p>In general, your images and video titles should not contain profanity, threats, hate speech or promote violence and other illegal activities. While our guidelines cannot cover every possible scenario, we expect our users to exercise common sense.</p>
                <p>Failure to comply may result in the removal of content and/or suspension or termination of your account. We reserve the right to review and remove any content that violates these guidelines.</p>

                <h2 className="policy-headline">4. Subscription Plans</h2>
                <p>We offer several optional subscription plans that offer additional features and benefits. Subscriptions are handled through Stripe, on a monthly basis, and auto-renew. They can be canceled at any time, with service remaining available until the end of the billing cycle. Users can also switch between subscription tiers. More details on subscription handling can be found in Section 7 below.</p>

                <h2 className="policy-headline">5. Surveys</h2>
                <p>Users participate in surveys by selecting images they prefer. Users must act honestly and not abuse the system by randomly clicking to quickly gain impressions. Although we generally believe in the honesty of our users, we have systems in place to detect such abuse.</p>

                <h2 className="policy-headline">6. Use of Service</h2>
                <p>You agree to use our service in accordance with all applicable local, state, national, and international laws and regulations.</p>

                <h2 className="policy-headline">7. Billing and Subscription Policy</h2>
                <p>a. <strong>Subscription Tiers:</strong> Various subscription options are available.</p>
                <p>b. <strong>Cancelation Policy:</strong> Subscriptions can be canceled at any time, with services continuing until the end of the billing cycle.</p>
                <p>c. <strong>Downgrades and Upgrades:</strong> Users can downgrade or upgrade their subscription at any time. Downgrades take effect at the end of the billing cycle. For upgrades, users may choose whether to schedule the upgrade or apply it immediately.</p>

                <h2 className="policy-headline">8. Liability and Warranty Disclaimer</h2>
                <p>ThumbRater.com is provided on an "as-is" and "as-available" basis, and we expressly disclaim all warranties of any kind, whether express or implied.</p>

                <h2 className="policy-headline">9. Termination</h2>
                <p>We reserve the right to terminate or suspend your access to our service at any time, with or without notice, for any reason deemed appropriate by us, including, but not limited to, a breach of these Terms. We strive to maintain a fair service environment and may provide warnings or notices in certain cases prior to termination or suspension.</p>

                <h2 className="policy-headline">10. Changes to Terms</h2>
                <p>We reserve the right to change these Terms at any time. Continued use of the service after changes constitutes acceptance of the new Terms.</p>

                <h2 className="policy-headline">11. Contact Information</h2>
                <p>For any questions about these Terms, please contact us via the Feedback & Contact form at the bottom of this page.</p>
            </div>

            <div className="nav__buttons_bottom">
                <button onClick={goBack} className="button">Go Back</button>
            </div>
        </>
    );
}

export default TermsofService;
