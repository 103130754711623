import React from 'react';
import { Link } from 'react-router-dom';

const FAQ = () => (
    <>
        <div className="standard-container">
            <h2 className="headline">What is ThumbRater?</h2>
            <p className="standard-text">We simulate what happens on YouTube: users are shown a selection of thumbnails and <span className="standout">click the one they like best</span>. Instead of playing the video, we gather the click data.</p>
            <p className="standard-text">This way we can tell you exactly <span className="standout">how well your thumbnail performs</span>. Not happy with the result? No problem! Make improvements until the image is ready for primetime, aka YouTube.</p>
            <p className="standard-text">If your video is online already, that's fine, too. Improving a thumbnail to <span className="standout">get more views</span> is always a great idea and may even revive a dead video.</p>

            <h2 className="headline">Does ThumbRater cost money?</h2>
            <p className="standard-text">No, ThumbRater is free to use. Our paid subscription plans are for those who want optional extra features.</p>

            <h2 className="headline">Is ThumbRater an AI service?</h2>
            <p className="standard-text">No, in order to get accurate ratings, all thumbnails are rated by our human users. On the Rating screen you generate click data, and in return you receive data from other users for your own thumbnails.</p>

            <h2 className="headline">How does the rating system work?</h2>
            <p className="standard-text">The Rating screen always shows six random thumbnails. Users click the one they like best, and we calculate a rating based on how often thumbnails get clicked.</p>
            <p className="standard-text">To receive ratings for your thumbnails you need to earn Impression Credits by rating the thumbnails of other users. Alternatively, you can join one of our subscription plans, which include monthly Impression Credits.</p>

            <h2 className="headline">Isn't this the same as YouTube's Test & Compare?</h2>
            <p className="standard-text">No, ThumbRater has many advantages. We collect thousands of data points even for new and small channels, we allow title comparisons, you can compare more than 3 thumnbails at a time.</p>
            <p className="standard-text">But the key difference is that Test & Compare's "winner" <span className="standout">may not be a winner at all</span>. If Test & Compare tells you that thumbnail A got 40%, while B and C got 30% each, that makes A the "winner". Unfortunately, winning against two competitors tells you nothing at all about A's actual competition: all the other thumbnails on YouTube.</p>
            <p className="standard-text">ThumbRater measures performance against all other thumbnails ever rated, making its insights much more useful. Plus, you want this data before you publish, to get an edge over those who <span className="standout">waste precious YouTube impressions</span> by reyling on a three-split after the fact.</p>

            <h2 className="headline">How do I perform an A/B test?</h2>
            <p className="standard-text">Simply upload two or more variants of a thumbnail and make sure to set them to the same amount of target impressions. Let them run until the target impressions are reached, then compare their performance stats.</p>

            <h2 className="headline">Why does my thumbnail not get impressions?</h2>
            <p className="standard-text">It takes some time for other users to vote on your thumbnails, as all data is based on real human interaction with your images.</p>
            <p className="standard-text">ThumbRater launched in March 2024, and early-on patience will be needed until the user base has grown to a healthy size. Stick with us, and you'll soon be way ahead of the YouTube game!</p>

            <h2 className="headline">Can my thumbnail be shown to the same user twice?</h2>
            <p className="standard-text">No. If a user has already seen your thumbnail, it will not be shown to them again.</p>

            <h2 className="headline">How reliable is ThumbRater's verdict?</h2>
            <p className="standard-text">ThumbRater conducts a survey to figure out how good each thumbnail is. The more impressions your thumbnail has received the more accurate ThumbRater's verdict becomes.</p>
            <p className="standard-text">As a general guideline you can use the following numbers:</p>
            <ul>
                <li><span className="standout">100 impressions:</span> The recommended minimum before you draw any conclusions.</li>
                <li><span className="standout">300 impressions:</span> A decent sample size, the verdict is likely close to the truth.</li>
                <li><span className="standout">1,000 impressions:</span> Very solid data, with a 95% probability that the verdict is within 3% of the truth.</li>
                <li><span className="standout">5,000 impressions:</span> If you want to be REALLY sure, with a 99% probability that the verdict is within 2% of the truth.</li>
            </ul>

            <h2 className="headline">Why does the verdict say "Not enough impressions"?</h2>
            <p className="standard-text">We don't display a verdict for thumbnails that have had less than 50 impressions, because the data can be misleading when the sample size is this small.</p>

            <h2 className="headline">Why are some subscription plans unavailable?</h2>
            <p className="standard-text">ThumbRater launched very recently, and we need some time to grow our user base before we can guarantee the availability of thousands of impressions. Higher tier plans will become available as the site grows.</p>

            <h2 className="headline">Does ThumbRater calculate a CTR?</h2>
            <p className="standard-text">Yes, we calculate a clickthrough rate for each thumbnail: clicks divided by impressions.</p>

            <h2 className="headline">What is my CTR?</h2>
            <p className="standard-text">CTR depends on the context in which the thumbnail is shown. When someone performs a YouTube search, they might look through 25 results before they click on a video. The average CTR in that case would be 4%.</p>
            <p className="standard-text">Since ThumbRater always displays 6 thumbnails at once, the average CTR on ThumbRater is 16.6%. In order to avoid confusion, we don't display this raw CTR value. Instead, we show you how much better your thumbnail is compared to all other thumbnails that have ever been rated.</p>
        </div>

        <div className="nav__buttons_bottom">
            <Link to="/" className="button">Home</Link>
        </div>
    </>
);

export default FAQ;