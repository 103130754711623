import { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../features/auth/AuthProvider";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials, setEmailVerificationError } from '../features/auth/authSlice';
import { useSendVerificationEmailMutation } from '../features/auth/authApiSlice';
import { useRegisterMutation } from '../features/users/usersApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';
import { PWD_REGEX, USER_REGEX } from '../utility/utilityFunctions';

const Register = () => {
    const authContext = useContext(AuthContext)
    const { token } = authContext
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [sendVerificationEmail] = useSendVerificationEmailMutation();
    const [register] = useRegisterMutation();
    const [hasAgreedToPolicy, setHasAgreedToPolicy] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Eingeloggte User sollen die Register Seite gar nicht sehen
    useEffect(() => {
        if (token)
            navigate("/dash/images")

        const savedForm = sessionStorage.getItem("registerForm");
        if (savedForm) {
            const { username: savedUsername, email: savedEmail } = JSON.parse(savedForm);
            setUsername(savedUsername || '');
            setEmail(savedEmail || '');
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (username && email) {
            sessionStorage.setItem("registerForm", JSON.stringify({ username, email }));
        }
    }, [username, email]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const trimmedUsername = username.trim();
        const trimmedEmail = email.trim();

        if (!USER_REGEX.test(trimmedUsername)) {
            setErrMsg('Username must be 4-20 letters or numbers. Allowed special characters: _\'&-');
            return;
        }

        if (!validator.isEmail(trimmedEmail)) {
            setErrMsg('Invalid email format.');
            return;
        }

        if (!PWD_REGEX.test(password)) {
            setErrMsg('Passwords must be 8-20 letters or numbers without spaces. Allowed special characters: !@#%_&-');
            return;
        }

        if (password !== confirmPassword) {
            setErrMsg('Passwords must match.');
            return;
        }

        setIsProcessing(true);

        try {
            const result = await register({ username: trimmedUsername, email: trimmedEmail, password });

            if (!result.error) {
                sessionStorage.removeItem('registerForm');

                // dispatch the received accessToken to the store
                dispatch(setCredentials({ accessToken: result.data.accessToken }));
                setUsername('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');

                try {
                    await sendVerificationEmail(trimmedEmail).unwrap();
                    dispatch(setEmailVerificationError(undefined));
                } catch (err) {
                    dispatch(setEmailVerificationError('There was an issue sending the verification email. Please make sure you have your email address entered correctly.'));
                }

                navigate('/dash/images');
            } else {
                setErrMsg(result.error.data.message);
            }
        } catch (err) {
            setErrMsg('There was an issue sending your request. Please try again later.');
        } finally {
            setIsProcessing(false);
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value);
    const handleEmailInput = (e) => setEmail(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const handleConfirmPwdInput = (e) => setConfirmPassword(e.target.value);

    const content = (
        <>
            <div className="standard-container-centered">
                <form className="form-login" onSubmit={handleSubmit}>
                    <label htmlFor="username" className="form-login__label">Username</label>
                    <input
                        className="form-login__input"
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUserInput}
                        autoComplete="username"
                        maxLength="20"
                        required
                    />

                    <label htmlFor="email" className="form-login__label">Email</label>
                    <input
                        className="form-login__input"
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailInput}
                        autoComplete="email"
                        maxLength="100"
                        required
                    />

                    <label htmlFor="password" className="form-login__label">Password</label>
                    <input
                        className="form-login__input"
                        type="password"
                        id="password"
                        onChange={handlePwdInput}
                        value={password}
                        autoComplete="new-password"
                        maxLength="20"
                        required
                    />

                    <label htmlFor="confirmPassword" className="form-login__label">Confirm Password</label>
                    <input
                        className="form-login__input"
                        type="password"
                        id="confirmPassword"
                        onChange={handleConfirmPwdInput}
                        value={confirmPassword}
                        autoComplete="new-password"
                        maxLength="20"
                        required
                    />

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "12px", marginBottom: "0px", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px" }}>
                        <span style={{ marginLeft: "7px", fontSize: "0.8em" }}>
                            By registering you agree to the Terms of Service and Privacy Policy.
                        </span>
                    </div>

                    {isProcessing ? (
                        <div className="spinner-wrapper">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        </div>
                    ) : (
                        <button
                            className={`button ${isProcessing ? 'disabled' : ''}`}
                            style={{ marginTop: "5px" }}
                            disabled={isProcessing}
                        >
                            Register
                        </button>
                    )}

                    {errMsg && <p className="error-message">{errMsg}</p>}

                    <Link to="/login" className="forgot-login-link">Already have an account? Login here</Link>
                </form>
            </div >
        </>
    );

    return content;
}

export default Register;
