import React, { useContext, useState, useEffect } from 'react';
import { useGetRatingImagesQuery, useGetImagesQuery, useRateImagesMutation } from "./imagesApiSlice";
import ImgRating from './ImgRating';
import { Link } from 'react-router-dom';
import { UserContext } from "../auth/ProtectedRoutes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import EmailVerification from '../auth/EmailVerification';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';

const Rating = () => {
    const [currentImages, setCurrentImages] = useState(undefined);
    const [nextImages, setNextImages] = useState(undefined);
    const [viewedImages, setViewedImages] = useState([]);
    const [queryParam, setQueryParam] = useState([]);

    const user = useContext(UserContext);
    const {
        username,
        email,
        noVotingUntil: noVotingUntilString,
        active: isActive
    } = user || {};

    const {
        data: images,
        isUninitialized,
        isFetching,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetRatingImagesQuery(queryParam, {
        refetchOnMountOrArgChange: true,
        staleTime: 0
    });

    const [refreshingImages, setRefreshingImages] = useState(true);
    const [rateImages, { isLoading: isRating, isSuccess: ratingSuccess, isError: ratingError }] = useRateImagesMutation();
    const [errMsg, setErrMsg] = useState("");

    let content;

    useEffect(() => {
        setCurrentImages(undefined);
        setNextImages(undefined);
        setQueryParam([]);
    }, []);

    useEffect(() => {
        if (isSuccess && !isFetching && !isLoading) {
            if (!currentImages) {
                setCurrentImages(images);
            } else if (!nextImages && images.ids.every(id => !currentImages.ids.includes(id))) {
                setNextImages(images);
            } else if (!nextImages) {
                //console.log("Warning: Overlapping IDs in currentImages and preloaded images, not setting nextImages!");
            } else if (nextImages) {
                //console.log("Warning: Already had nextImages, ignoring fetch result.");
            }
        }
    }, [images, isSuccess, currentImages, nextImages]);

    const updateViewedImagesAndFetchNext = (newImages) => {
        setViewedImages(prevViewedImages => {
            const combinedImages = [...prevViewedImages, ...newImages];
            const uniqueImages = combinedImages.reduceRight((acc, current) => {
                if (!acc.some(image => image.id === current.id)) {
                    return [current, ...acc];
                }
                return acc;
            }, []).slice(-30); // Keep only the last 30 entries
    
            const uniqueImageIds = uniqueImages.map(image => image.id);
            setNextImages(undefined);
            setQueryParam(uniqueImageIds); // Wir preloaden den naechsten Batch
    
            return uniqueImages;
        });
    };

    useEffect(() => {
        if (currentImages && currentImages.ids.length == 6) {
            const newImages = currentImages.ids.map(id => currentImages.entities[id]);
            updateViewedImagesAndFetchNext(newImages);
        }
        else {
            //console.log("NOT getting next images, because: ", currentImages)
        }
    }, [currentImages]);

    if (!currentImages) {
        content = (
            <>
                <h2 className="headline" style={{ marginBottom: "0.6rem" }}>Fetching Data</h2>
                <h4 className="subheadline">We'll be ready in a jiffy</h4>
                <div className="spinner-wrapper">
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </div>
                <div className="nav__buttons_bottom" style={{ marginTop: "20px" }}>
                    <Link to="/dash/images" className="button">Dashboard</Link>
                </div>
            </>
        )
        return content
    }

    if (isError) {
        let errorMessage = '';
        if (error.status === 'FETCH_ERROR' || !error.status) {
            errorMessage = 'The server is not responding. Please try again later.';
        } else {
            errorMessage = error?.data?.message || 'An unknown error occurred';
        }
        content = (
            <>
                <p className="error-message">{errorMessage}</p>
            </>
        );
    }
    else if (ratingError) {
        content = (<><p className="error-message">There was an issue with the server request. Please try again later.</p></>)
    }

    if (noVotingUntilString) {
        let timeOptions = { hour: '2-digit', minute: '2-digit' };
        let noVotingUntil = new Date(noVotingUntilString);
        let isPermanentlyDisabled = noVotingUntil.getFullYear() > 2200;

        return (
            <div className="dark-box" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <h3>Voting Privileges {isPermanentlyDisabled ? 'Permanently Disabled' : 'Suspended'}</h3>
                {isPermanentlyDisabled ? (
                    <>
                        <p>Your ability to vote has been permanently disabled due to multiple violations of our <span className="standout">Terms of Service.</span></p>
                        <p>If you believe that this action is an error or if you have any concerns regarding this matter, please do not hesitate to use the Feedback & Contact form at the bottom of the page.</p>
                    </>
                ) : (
                    <>
                        <p>We regret to inform you that your ability to vote has been temporarily suspended, because our systems have detected abusive voting behavior.</p>
                        <p>As outlined in our <span className="standout">Terms of Service</span>, all voting must be conducted honestly and in good faith. Everybody's quality of data depends on honest voting.</p>
                        <p>Your voting privileges will be restored on <span className="standout">{noVotingUntil.toLocaleDateString()} {noVotingUntil.toLocaleTimeString([], timeOptions)}</span>.</p>
                        <p>If you believe that this suspension is an error or if you have any concerns regarding this matter, please do not hesitate to use the Feedback & Contact form at the bottom of the page.</p>
                    </>
                )}
                <div className="nav__buttons_bottom" style={{ marginTop: "20px" }}>
                    <Link to="/dash/images" className="button">Dashboard</Link>
                </div>
            </div>
        )
    }
    else if (currentImages.ids.length !== 6) {
        return (
            <div className="dark-box" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <h3>No images currently available to rate</h3>
                <p>ThumbRater launched very recently, and we're actively working on populating our image inventory.</p>
                <p>Please check back later. We appreciate your patience!</p>
                <div className="nav__buttons_bottom" style={{ marginTop: "20px" }}>
                    <Link to="/dash/images" className="button">Dashboard</Link>
                </div>
            </div>
        )
    }

    const imageObjects = currentImages.ids.map(id => currentImages.entities[id]).slice(0, 6);

    const handleImageClick = async (clickedImageId) => {
        //console.log("Clicked image id:", clickedImageId)
        const clickedImage = imageObjects.find(image => image.id === clickedImageId);
        //console.log("Clicked image object:", clickedImage)

        if (clickedImage.videoLink) {
            Swal.fire({
                title: 'Watch Video Now?',
                html: "The creator of this thumbnail specified a video link.<br/>Would you like to watch it in a new tab?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, watch it!',
                cancelButtonText: 'No thanks!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    window.open(clickedImage.videoLink, '_blank');
                }
                // Proceed with image rating whether user accepted or declined
                await rateImageRoutine(clickedImageId);
            });
        } else {
            // Proceed with image rating directly if no videolink
            await rateImageRoutine(clickedImageId);
        }
    }

    const rateImageRoutine = async (clickedImageId) => {
        Swal.fire({
            icon: 'success',
            title: 'Rating Confirmed',
            showConfirmButton: false,
            timer: 700
        })

        //console.log("rateImageRoutine, current:", currentImages)
        //console.log("rateImageRoutine, next:", nextImages)

        setRefreshingImages(true);
        setCurrentImages(nextImages);

        try {
            const images = imageObjects.map(image => ({
                imageID: image.id,
                clicked: image.id === clickedImageId
            }));
            const result = await rateImages(images);

            if (!result || result.error) {
                setErrMsg(result?.error?.data?.message || "There was an error with the rating.");
            } else {
                setErrMsg("")
            }
        } catch (err) {
            setErrMsg("There was an issue with the server request. Please try again later.")
        }
    }

    if (errMsg) {
        content = (<p className="errmsg">{errMsg}</p>)
    } else if (isSuccess && isActive) {
        content = (
            <>
                <h2 className="headline" style={{ marginBottom: "0.6rem" }}>Which video would you watch?</h2>
                <h4 className="subheadline">Click the thumbnail you like best</h4>
                <div className="white-bg">
                    <div className="rating-container">
                        {imageObjects.map((image) => <ImgRating key={image.id} img={image} onImageClick={handleImageClick} />)}
                    </div>
                </div>
                <div className="nav__buttons_bottom" style={{ marginTop: "20px" }}>
                    <Link to="/dash/images" className="button">Dashboard</Link>
                </div>
            </>
        )
    } else if (isSuccess && !isActive && username !== undefined) {
        content = <EmailVerification email={email} isActive={isActive} />;
    }

    return (
        <>
            {content}
        </>
    );
};

export default Rating;