import React, { useEffect, useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useGetImageQuery, useUpdateImageMutation, useDeleteImageMutation } from './imagesApiSlice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getImageURL } from "../../utility/utilityFunctions";
import { UserContext } from "../auth/ProtectedRoutes";
import ChartView from '../../components/Chartview';
import Swal from 'sweetalert2';
import ImageDataForm from './ImageDataForm';
import useImageName from '../../hooks/useImageName';
import useVideoTitle from '../../hooks/useVideoTitle';
import useImageActive from '../../hooks/useImageActive';
import useVideoLink from '../../hooks/useVideoLink';
import useTargetImpressions from '../../hooks/useTargetImpressions';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { getVideoLinkAllowed } from '../../utility/utilityFunctions';

const EditImage = ({ open, handleClose, id, verdict }) => {
    const user = useContext(UserContext)
    const { data: image, isLoading: isLoadingData, isSuccess: isSuccessData, isError: isErrorData } = useGetImageQuery(id, {
        refetchOnMountOrArgChange: true
    });

    const [
        updateImage,
        { isLoading },
    ] = useUpdateImageMutation();

    const [
        deleteImage,
        { isLoading: isDeleting },
    ] = useDeleteImageMutation();

    const handleCloseAndReset = () => {
        resetImgName();
        resetVideoTitle();
        resetImgActive();
        resetVideoLink();
        resetTargetImpressions();
        handleClose();
    };

    const [errMsg, setErrMsg] = useState("");
    const [targetImpressions, setTargetImpressions] = useState(image?.targetImpressions);
    const [imgActive, setImgActive] = useState(image?.active || false);
    const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);

    const { imgName, onImgNameChanged, reset: resetImgName } = useImageName(image?.imgname, setErrMsg);
    const { videoTitle, onTitleChanged, reset: resetVideoTitle } = useVideoTitle(image?.videotitle === "N/A" ? "" : image?.videotitle || "", image?.impressions, setErrMsg);
    const { onImgActiveChanged } = useImageActive(setImgActive, imgActive, image?.impressions, targetImpressions, setTargetImpressions);
    const { videoLink, onVideoLinkChanged, reset: resetVideoLink } = useVideoLink(image?.videoLink, setErrMsg);
    const { onTargetImpressionsChanged } = useTargetImpressions(targetImpressions, setTargetImpressions, image?.impressions);

    useEffect(() => {
        if (image?.active) {
            setImgActive(image?.active);
        }
        else {
            setImgActive(false)
        }

        if (image?.targetImpressions) {
            setTargetImpressions(image.targetImpressions);
        }
    }, [image]);

    useEffect(() => {
        setErrMsg("");
    }, [isSuccessData, image, open]);

    const canSave = Boolean(imgName) && !isLoading && !isLoadingData && !isDeleting && !errMsg
    const canDelete = !isLoading && !isLoadingData && !isDeleting

    const resetTargetImpressions = () => {
        setTargetImpressions(image?.targetImpressions)
    }

    const resetImgActive = () => {
        setImgActive(image?.active)
    }

    const onSaveImageClicked = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (canSave) {
            const requestData = {
                id: image.id,
                targetImpressions,
                imgname: imgName,
                videotitle: videoTitle,
                active: imgActive,
                videoLink
            };
            const result = await updateImage(requestData);
            if (result && !result.error) {
                handleCloseAndReset();
            }
            else {
                setErrMsg(result?.error?.data?.message || "There was an error saving the image.");
            }
        }
    };

    const onDeleteImageClicked = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this image or its data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsDeletionInProgress(true);
                const result = await deleteImage({ id: image.id });
                if (!result || result.error) {
                    setErrMsg(result?.error?.data?.message || "There was an error deleting the image.");
                }
                else {
                    setErrMsg("");
                    handleCloseAndReset();
                }
                setIsDeletionInProgress(false);
            }
        });
    };

    if (isLoadingData || isLoading || isDeleting || !image) {
        return <LoadingDialog open={open} handleCloseAndReset={handleCloseAndReset} />
    }

    if (isErrorData && !isDeletionInProgress) {
        return <ErrorDialog open={open} handleCloseAndReset={handleCloseAndReset} errorMessage="Error loading image data." />
    }

    const imagePath = getImageURL(image)
    const videoLinkAllowed = getVideoLinkAllowed(user.plan)

    return (
        <Dialog open={open} onClose={(e) => {
            e.stopPropagation();
            handleClose();
        }} PaperProps={{ className: 'MuiDialog-paper' }}>
            <>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCloseAndReset();
                    }}
                    aria-label="close"
                    className="MuiIconButton-root"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
                <DialogContent className="muidialog-content">
                    <form className="form-editimage" onSubmit={onSaveImageClicked}>

                        <ImageDataForm
                            headline="Image Details"
                            imgName={imgName}
                            videoTitle={videoTitle}
                            videoLink={videoLink}
                            videoLinkAllowed={videoLinkAllowed}
                            imgActive={imgActive}
                            impressions={image.impressions}
                            targetImpressions={targetImpressions}
                            onImgNameChanged={onImgNameChanged}
                            onTitleChanged={onTitleChanged}
                            onVideoLinkChanged={onVideoLinkChanged}
                            onImgActiveChanged={onImgActiveChanged}
                            onTargetImpressionsChanged={onTargetImpressionsChanged}
                        />

                        <div className="detail-chart-wrapper">
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={imagePath}
                                    className="bordered-imageupload"
                                    alt="Uploaded content"
                                />
                                {!imgActive &&
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ position: 'relative' }}>
                                            <FontAwesomeIcon
                                                icon={faBan}
                                                className="ban-icon"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="badge-container detail-badge-container">
                                <div className="badge-button-container">
                                    <img className="badge-button-image" src={`/img/icon_badge_round_001_${verdict.color}.png`} alt="Rating Icon" />
                                    <div className="badge-button-text">
                                        {verdict.raw >= 0 ? (
                                            <>
                                                {verdict.prefix}
                                                <br />
                                                {verdict.percentage}
                                            </>
                                        ) : (
                                            verdict.prefix
                                        )}
                                    </div>
                                </div>
                                <div className="badge-badge-container">
                                    <img className="badge-badge-image" src={`/img/icon_badge_rect_001_${verdict.color}.png`} alt="Badge Icon" />
                                    <div className="badge-badge-text">{verdict.comment}</div>
                                </div>
                            </div>
                        </div>

                        <div className="dark-box dark-box-tight">
                            {verdict.raw >= 0 ? (
                                <ChartView dataPoint={Math.floor(verdict.raw)} />
                            ) : (
                                <div className="chart-unavailable-text">
                                    <span className="standout">Chart data unavailable</span>
                                    <br />
                                    Image requires at least 50 impressions
                                </div>
                            )}
                        </div>

                        {errMsg && <p className="errmsg">{errMsg}</p>}

                        <div className="nav__buttons_bottom">
                            <button
                                className={`button ${!canDelete && "disabled"}`}
                                title="Delete"
                                disabled={!canDelete}
                                style={{ marginTop: "12px" }}
                                type="button"
                                onClick={onDeleteImageClicked}
                            >Delete Image
                            </button>

                            <button
                                className={`button ${!canSave && "disabled"}`}
                                title="Save"
                                disabled={!canSave}
                                style={{ marginTop: "12px" }}
                                type="submit"
                            >Save
                            </button>

                            <button
                                className="button"
                                title="Cancel"
                                style={{ marginTop: "12px" }}
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCloseAndReset();
                                }}
                            >Cancel
                            </button>

                        </div>
                    </form>
                </DialogContent>
            </>
        </Dialog>
    )
}

export default EditImage
