import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const galleryImagesAdapter = createEntityAdapter({})
const ratingImagesAdapter = createEntityAdapter({})

const galleryInitialState = galleryImagesAdapter.getInitialState()
const ratingInitialState = ratingImagesAdapter.getInitialState()

// Base query is already inside our imagesApiSlice
export const imagesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getImages: builder.query({
            query: () => `/images`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedImages = responseData.map(image => {
                    image.id = image._id  // Our backend images collection has a field "id", but the auto field is always called _id
                    return image
                });
                return galleryImagesAdapter.setAll(galleryInitialState, loadedImages)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Img', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Img', id }))
                    ]
                } else return [{ type: 'Img', id: 'LIST' }]
            }
        }),
        getRatingImages: builder.query({
            query: (excludeIDs) => ({
                url: `/images/ratingsample`,
                method: 'POST',
                body: { excludeIDs }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedImages = responseData.map(image => {
                    image.id = image._id  // Our backend images collection has a field "id", but the auto field is always called _id
                    return image
                });
                return ratingImagesAdapter.setAll(ratingInitialState, loadedImages)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Img', id: 'RATINGLIST' },
                        ...result.ids.map(id => ({ type: 'Img', id }))
                    ]
                } else return [{ type: 'Img', id: 'RATINGLIST' }]
            }
        }),
        getImage: builder.query({
            query: (imageId) => `/images/data/${imageId}`,
            transformResponse: (responseData = {}) => {
                responseData.id = responseData._id || null;
                return responseData;
            },
            providesTags: (result, error, arg) => (
                result ? [{ type: 'Img', id: result.id }] : []
            )
        }),
        getImagePath: builder.query({
            query: (imageId) => `/images/${imageId}`,
            providesTags: (result, error, imageId) => [{ type: "Path", id: imageId }],
        }),
        addNewImage: builder.mutation({
            query: (initialImage) => {
                // Convert initialImage object to FormData
                const formData = new FormData();
                Object.entries(initialImage).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                return {
                    url: "/images",
                    method: "POST",
                    body: formData, // Pass the FormData object as the body
                };
            },
            invalidatesTags: [{ type: "Img", id: "LIST" }],
        }),
        updateImage: builder.mutation({
            query: initialImage => ({
                url: '/images',
                method: 'PATCH',
                body: {
                    ...initialImage,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Img', id: arg.id }
            ]
        }),
        rateImages: builder.mutation({
            query: images => ({
                url: '/images/rate',
                method: 'PATCH',
                body: images
            }),
            invalidatesTags: (result, error, arg) => [
                ...arg.map(image => ({ type: 'Img', id: image.imageID })),
                { type: 'User', id: 'current' },
                { type: 'Img', id: 'RATINGLIST' }
            ]
        }),
        deleteImage: builder.mutation({
            query: ({ id }) => ({
                url: `/images`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Img', id: arg.id }
            ]
        }),
    }),
})

// Die Hooks werden automatisch erstellt, matchen automatisch die Endpoint von oben: getImages, addNewImage etc.
export const {
    useGetImagesQuery,
    useGetRatingImagesQuery,
    useGetImageQuery,
    useGetImagePathQuery,
    useAddNewImageMutation,
    useUpdateImageMutation,
    useRateImagesMutation,
    useDeleteImageMutation
} = imagesApiSlice;

// returns the query result object
export const selectImagesResult = state => imagesApiSlice.endpoints.getImages.select()(state);
export const selectRatingImagesResult = state => imagesApiSlice.endpoints.getRatingImages.select()(state);

// createSelector creates a memoized selector
const selectGalleryImagesData = createSelector(selectImagesResult, imagesResult => imagesResult.data)
const selectRatingImagesData = createSelector(selectRatingImagesResult, imagesResult => imagesResult.data)

export const {
    selectAll: selectAllGalleryImages,
    selectById: selectGalleryImageById,
    selectIds: selectGalleryImageIds
} = galleryImagesAdapter.getSelectors(state => selectGalleryImagesData(state) ?? galleryInitialState)

export const {
    selectAll: selectAllRatingImages,
    selectById: selectRatingImageById,
    selectIds: selectRatingImageIds
} = ratingImagesAdapter.getSelectors(state => selectRatingImagesData(state) ?? ratingInitialState)