import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function useImageActive(setImgActive, imgActive, impressions, targetImpressions, setTargetImpressions) {

    useEffect(() => {
        if (targetImpressions <= impressions) {
            setImgActive(false);
        }
    }, [targetImpressions, impressions]);

    const onImgActiveChanged = (e) => {
        if (!imgActive && e.target.checked && impressions >= targetImpressions) {
            Swal.fire({
                title: 'Already reached target impressions',
                html: 'To set this image active, please increase its target impressions.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Got it'
            })
        }
        else {
            setImgActive(e.target.checked);
        }
    };

    const reset = () => {
        setImgActive(false);
        setTargetImpressions(targetImpressions);
    };

    return { onImgActiveChanged, reset };
}  

export default useImageActive