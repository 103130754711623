import { useState, useEffect, useContext } from 'react'
import { AuthContext } from "../features/auth/AuthProvider";
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../features/auth/authSlice'
import { useLoginMutation } from '../features/auth/authApiSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const authContext = useContext(AuthContext)
    const { token } = authContext
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login] = useLoginMutation();

    // Eingeloggte User sollen die Login Seite gar nicht sehen
    useEffect(() => {
        if (token)
            navigate("/dash/images")
    }, [token]);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username.trim() || !password.trim()) {
            setErrMsg('Both fields are required');
            return;
        }

        setErrMsg('');
        setIsProcessing(true);

        try {
            const { accessToken } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');
            sessionStorage.removeItem('registerForm');
            navigate('/dash/images');
        } catch (err) {
            console.log("ERROR: ", err);

            if (!err.status || err.status === "FETCH_ERROR") {
                setErrMsg('The server is not responding. Please try again later.');
            } else if (err.status === 400) {
                setErrMsg('Username and password are required.');
            } else if (err.status === 401) {
                setErrMsg(err.data?.message);
            } else {
                setErrMsg(err.data?.message);
            }
        } finally {
            setIsProcessing(false);
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);

    const content = (
        <>
            <div className="standard-container-centered">
                <form className="form-login" onSubmit={handleSubmit}>
                    <label htmlFor="username" className="form-login__label">Username</label>
                    <input
                        className="form-login__input"
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUserInput}
                        autoComplete="username"
                        maxLength="20"
                        required
                    />

                    <label htmlFor="password" className="form-login__label">Password</label>
                    <input
                        className="form-login__input"
                        type="password"
                        id="password"
                        onChange={handlePwdInput}
                        value={password}
                        autoComplete="current-password"
                        maxLength="20"
                        required
                    />
                    {isProcessing ? (
                        <div className="spinner-wrapper">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        </div>
                    ) : (
                        <button className="button" style={{ marginTop: "20px" }} disabled={isProcessing}>Sign In</button>
                    )}

                    {errMsg && <p className="error-message">{errMsg}</p>}

                    <Link to="/forgotlogin" className="forgot-login-link">Forgot username or password?</Link>
                    <Link to="/register" className="forgot-login-link">No account yet? Register here</Link>
                </form>
            </div>
        </>
    )
    return content
}

export default Login