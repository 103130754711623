import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ErrorDialog({ open, handleCloseAndReset, errorMessage }) {
    return (
        <Dialog open={open} onClose={(e) => {
            e.stopPropagation();
            handleCloseAndReset();
        }} PaperProps={{ className: 'MuiDialog-paper' }}>
            <IconButton
                edge="end"
                color="inherit"
                onClick={(e) => {
                    e.stopPropagation();
                    handleCloseAndReset();
                }}
                aria-label="close"
                className="MuiIconButton-root"
            >
                <CloseIcon fontSize="large" />
            </IconButton>
            <DialogContent className="muidialog-content">
                <p style={{ color: "white" }}>{errorMessage}</p>
            </DialogContent>
        </Dialog>
    )
}

export default ErrorDialog;