import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSendVerificationEmailMutation } from '../auth/authApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const EmailVerification = ({ email, isActive }) => {
    const emailVerificationError = useSelector(state => state.auth.emailVerificationError);
    const [sendVerificationEmail] = useSendVerificationEmailMutation();
    const [emailStatus, setEmailStatus] = useState({ message: emailVerificationError || "", isError: !!emailVerificationError });
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [sendEmailSuccess, setSendEmailSuccess] = useState(false);

    useEffect(() => {
        if (emailVerificationError) {
            setEmailStatus({ message: emailVerificationError, isError: true });
        }
    }, [emailVerificationError]);

    const resendEmail = async () => {
        setIsSendingEmail(true);
        try {
            await sendVerificationEmail(email).unwrap();
            setEmailStatus({ message: 'Verification email sent. Please check your inbox.', isError: false });
            setSendEmailSuccess(true);
        } catch (error) {
            setEmailStatus({ message: 'Error resending the verification email. Please ensure the email address entered in your Account Details is correct or try again later.', isError: true });
        }
        setIsSendingEmail(false);
    };

    //console.log("EmailVerification, isActive:", isActive)

    if (isActive) {
        return null;
    } else {
        return (
            <div className="darkish-box">
                <p>Your email is not verified yet. Please check your email for a verification link.</p>
                {emailStatus.isError && <p className="error-message">{emailStatus.message}</p>}
                {sendEmailSuccess && !emailStatus.isError && <p className="success-message">Verification email successfully sent. Please check your inbox.</p>}
                <div className="nav__buttons_bottom">
                    {isSendingEmail ? (
                        <div className="spinner-wrapper">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        </div>
                    ) : (
                        <button className="button" onClick={resendEmail}>Resend Verification Email</button>
                    )}
                </div>
            </div>
        );
    }
};

export default EmailVerification;