import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../auth/authApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PWD_REGEX } from '../../utility/utilityFunctions';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [resetPassword] = useResetPasswordMutation();

    const handlePasswordInput = (e) => setPassword(e.target.value);
    const handleConfirmPasswordInput = (e) => setConfirmPassword(e.target.value);

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            setErrorMessage('Please fill in both password fields.');
            return;
        }

        if (!PWD_REGEX.test(password)) {
            setErrorMessage('Passwords must be 8-20 letters or numbers without spaces. Allowed special characters: !@#%_&-');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords must match.');
            return;
        }

        setErrorMessage("");
        setIsProcessing(true);

        try {
            const result = await resetPassword({ token, password });
            if (!result.error) {
                // Reset successful
                setPasswordReset(true);
            } else {
                setErrorMessage(result.error.data.message);
            }
        } catch (err) {
            setErrorMessage("There was an issue resetting your password. Please try again later.");
        }
        setIsProcessing(false);
    };

    return (
        <>
            <div className="standard-container-centered">
                {!passwordReset ? (
                    <form className="form-login" onSubmit={e => e.preventDefault()}>
                        <label htmlFor="password" className="form-login__label">New Password:</label>
                        <input
                            className="form-login__input"
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordInput}
                            autoComplete="off"
                            maxLength="20"
                            required
                        />

                        <label htmlFor="confirmPassword" className="form-login__label">Confirm Password:</label>
                        <input
                            className="form-login__input"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordInput}
                            autoComplete="off"
                            maxLength="20"
                            required
                        />

                        {isProcessing ? (
                            <div className="spinner-wrapper">
                                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                            </div>
                        ) : (
                            <div className="nav__buttons_bottom">
                                <button className="button" onClick={handleResetPassword} disabled={isProcessing}>Apply New Password</button>
                            </div>)}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </form>
                ) : (
                    <div>
                        <p>Your password has been successfully changed. You can now log in with your new password.</p>
                    </div>
                )}
            </div>
        </>
    )
}

export default ResetPassword;
