import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function useVideoTitle(initialTitle = "", impressions, setErrorMsg, setErrorIsWarning) {
    const [videoTitle, setTitle] = useState(initialTitle);

    useEffect(() => {
        setTitle(initialTitle);
    }, [initialTitle]);

    const onTitleChanged = (e) => {
        if (impressions > 0) {
            Swal.fire({
                title: 'Cannot change video title',
                html: 'This image has already received impressions. Changing the video title now would skew the data.<br/><br/>To compare how well another video title works, please upload the same thumbnail again with a new title.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Got it'
            })
        }
        else {
            const title = e.target.value;

            if (title.length > 100) {
                setErrorMsg('Title may not be longer than 100 characters.');
                if (setErrorIsWarning)
                    setErrorIsWarning(false);
                return;
            }

            setTitle(title);
            setErrorMsg('');
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }
    }

    const reset = () => {
        setErrorMsg("")
        if (setErrorIsWarning)
            setErrorIsWarning(false)
        setTitle(initialTitle);
    };

    return { videoTitle, onTitleChanged, reset };
}

export default useVideoTitle