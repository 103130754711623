import { useContext } from "react"
import { AuthContext } from "../features/auth/AuthProvider";
import React from 'react';
import { Link } from 'react-router-dom';
import ChartView from "./Chartview";

const Public = () => {
    const authContext = useContext(AuthContext)
    const { token } = authContext

    return (
        <>
            <div className="inner-container">
                <h1 className="headline first-headline">Want More YouTube Views?</h1>
                <p className="standard-landing">Then you need great video thumbnails, because the <span style={{whiteSpace: "nowrap"}}>YouTube algorithm</span> <span className="standout" style={{whiteSpace: "nowrap"}}>brutally punishes</span> a lack of clicks.</p>

                <h2 className="headline">It's Data, Not Guesswork</h2>
                <p className="standard-landing">Optimize your <span className="standout">Click-Through Rate</span> before the video goes live. Leave your competitors in the dust - they guess what works, you <span className="standout">know</span> what does.</p>

                <h2 className="headline">Get Your Thumbnails Rated</h2>
                <p className="standard-landing">Upload your thumbnail, or multiple variants for <span className="standout" style={{whiteSpace: "nowrap"}}>A/B testing</span>, to find out how many clicks you attract.</p>
            </div>

            <div className="dark-box dark-box-margin-top">
                <h1 className="headline" style={{ marginTop: 0 }}>Be Ahead of the YouTube Game</h1>

                <div className="chart-container">
                    <div className="chart-wrapper">
                        <img src="/img/thumbnailBad.jpg" alt="Bad Thumbnail" className="bordered-image" />

                        <div className="badge-container">
                            <div className="badge-button-container">
                                <img className="badge-button-image" style={{width: "70px"}} src="/img/icon_badge_round_001_cyan.png" alt="Rating Icon" />
                                <div className="badge-button-text">Top<br />50%</div>
                            </div>
                            <div className="badge-badge-container">
                                <img className="badge-badge-image" src="/img/icon_badge_rect_001_cyan.png" alt="Badge Icon" />
                                <div className="badge-badge-text">Above<br />Average</div>
                            </div>
                        </div>

                        <ChartView dataPoint={54} />
                    </div>

                    <div className="chart-wrapper">
                        <img src="/img/thumbnailGood.jpg" alt="Good Thumbnail" className="bordered-image" />

                        <div className="badge-container">
                            <div className="badge-button-container">
                                <img className="badge-button-image" style={{width: "70px"}} src="/img/icon_badge_round_001_gold.png" alt="Rating Icon" />
                                <div className="badge-button-text">Top<br />20%</div>
                            </div>
                            <div className="badge-badge-container">
                                <img className="badge-badge-image" src="/img/icon_badge_rect_001_gold.png" alt="Badge Icon" />
                                <div className="badge-badge-text">Very Good</div>
                            </div>
                        </div>

                        <ChartView dataPoint={83} />
                    </div>
                </div>

                <h1 className="headline" style={{ marginTop: 0 }}>Get More Video Views</h1>

                <div className="chart-container">
                    <div className="chart-wrapper">
                        <div className="funnel">
                            <div className="funnel-row">Impressions<br /><span className="standout-avg">395.6K</span></div>
                            <div className="funnel-row">CTR<br /><span className="standout-avg">2.8%</span></div>
                            <div className="funnel-row">Video Views<br /><span className="standout-avg">11.2K</span></div>
                        </div>
                    </div>

                    <div className="chart-wrapper">
                        <div className="funnel">
                            <div className="funnel-row">Impressions<br /><span className="standout-good">1.7M</span></div>
                            <div className="funnel-row">CTR<br /><span className="standout-good">9.4%</span></div>
                            <div className="funnel-row">Video Views<br /><span className="standout-good">160.4K</span></div>
                        </div>
                    </div>
                </div>
            </div>

            {!token && (
                <div className="dark-box">
                    <h1 className="headline" style={{marginTop: "0.1rem"}}>Start Now, it's Free!</h1>

                    <div className="nav__buttons_bottom">
                        <Link to="/faq" className="button">FAQ</Link>
                        <Link to="/register" className="button">Register</Link>
                    </div>
                </div>)}
        </>
    )
};

export default Public;