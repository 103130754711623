import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from './api/apiSlice'
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import authSlice from "../features/auth/authSlice"

// Store wird erstellt und auch exportiert (ist in anderen Skripten verfuegbar). Initial State hat die beiden Fields, die in reducer angegeben sind.
export const store = configureStore({
    reducer: {
        // Brackets bedeuten, wir lesen den Wert, der in apiSlice.reducerPath steht und nehmen diesen als Fieldname
        // Dieser erste Reducer ist Boilerplate, damit die State Updates ueberhaupt funktionieren
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSlice.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})

// Zeile ist vermutl. nur notwendig wenn irgendwo refetchOnMount oder refetchOnReconnect verwendet wird, die Listener aus RTK Query sorgen fuer ein Refetch wenn ein solches Event stattfindet
setupListeners(store.dispatch)