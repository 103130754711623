import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../auth/ProtectedRoutes';
import { useDeleteUserMutation, useCancelSubscriptionMutation } from './usersApiSlice';
import { useSendLogoutMutation } from '../auth/authApiSlice';
import Swal from 'sweetalert2';

const DeleteUserAccount = () => {
    const user = useContext(UserContext);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [accountDeleted, setAccountDeleted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [deleteAccount] = useDeleteUserMutation();
    const [cancelSubscription] = useCancelSubscriptionMutation();
    const navigate = useNavigate();
    const [sendLogout] = useSendLogoutMutation();

    const handleLogout = async () => {
        try {
            await sendLogout().unwrap();
            navigate("/")
        } catch (error) {
            console.log('Error during logout: ', error);
        }
    }

    const handlePasswordInput = (e) => setPassword(e.target.value);

    const handleDeleteAccount = (e) => {
        e.preventDefault();

        if (!password) {
            setErrorMessage('Please enter your current password.');
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your account or any of its associated images! If you have an active subscription plan, you will get unsubscribed.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete my account!',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {

                setErrorMessage("");
                setIsProcessing(true);

                // Cancel the subscription first
                if (user.plan !== "Free") {
                    try {
                        const cancelResult = await cancelSubscription({ doCancel: true });

                        if (cancelResult.error) {
                            setErrorMessage(cancelResult.error.data.message);
                            setIsProcessing(false);
                            return; // Exit if the subscription cancellation fails
                        }
                    } catch (err) {
                        setErrorMessage("There was an issue canceling your subscription. Please try again later.");
                        setIsProcessing(false);
                        return; // Exit if the subscription cancellation fails
                    }
                }

                // Now proceed to delete the account
                try {
                    const deleteResult = await deleteAccount(password);

                    if (!deleteResult.error) {
                        setAccountDeleted(true);
                        handleLogout();
                    } else {
                        setErrorMessage(deleteResult.error.data.message);
                    }
                } catch (err) {
                    setErrorMessage("There was an issue deleting your account. Please try again later.");
                }

                setIsProcessing(false);
            }
        });
    };

    const backToAccountDetails = () => {
        navigate("/dash/user")
    }

    return (
        <>
            <div className="darkish-box">
                <div className="standard-container-centered" style={{ paddingBottom: "0px" }}>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Delete Account</h2>
                        <br></br>
                        This will permanently delete your account!<br></br><br></br>
                        If you want to change your subscription, e.g. to a Free Plan, you can do so at any time without deleting your account.
                    </div>
                </div>
                <div className="standard-container-centered" style={{ marginTop: "0px", paddingTop: "30px" }}>
                    {!accountDeleted ? (
                        <form className="form-login" onSubmit={e => e.preventDefault()}>

                            <label htmlFor="password" className="form-login__label">Current Password:</label>
                            <input
                                className="form-login__input"
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordInput}
                                autoComplete="off"
                                required
                            />

                            {isProcessing ? (
                                <div className="spinner-wrapper">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            ) : (
                                <div className="nav__buttons_bottom">
                                    <button className="button" onClick={backToAccountDetails} disabled={isProcessing}>Back</button>
                                    <button className="button" onClick={handleDeleteAccount} disabled={isProcessing}>Delete Account</button>
                                </div>)}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </form>
                    ) : (
                        <div>
                            <p>Your account has been successfully deleted.</p>
                            <div className="nav__buttons_bottom">
                                <button className="button" onClick={() => navigate('/login')} disabled={isProcessing}>Back to Login</button>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    )
}

export default DeleteUserAccount;
