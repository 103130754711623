import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo, faBan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import EditImage from './EditImage';
import { getImageURL } from "../../utility/utilityFunctions";
import Tooltip from '@mui/material/Tooltip';

const Img = ({ img, videoTitleHeadline }) => {
    const navigate = useNavigate();

    const [openEditImage, setOpenEditImage] = useState(false);

    const handleOpenEditImage = () => {
        //console.log("Img.js: handleOpenEditImage OPENING DIALOG")
        setOpenEditImage(true);
    }

    const handleCloseEditImage = () => {
        //console.log("Img.js: handleCloseEditImage CLOSING DIALOG")
        setOpenEditImage(false);
    }

    if (img) {
        const imagePath = getImageURL(img)
        const verdict = img.verdict
        const titleText = videoTitleHeadline ? img.videotitle : img.imgname;

        return (
            <div className="gallery-item">
                <Tooltip title={titleText} arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <p className="standout gallery-videotitle">{titleText}</p>
                </Tooltip>
                {imagePath ? (
                    <div className="gallery-img-wrapper" onClick={handleOpenEditImage}>
                        <img className="clickable-image clickable-shadow" src={imagePath} alt="Thumbnail" />
                        {!img.active &&
                            <FontAwesomeIcon
                                icon={faBan}
                                className="ban-icon-gallery"
                            />
                        }
                        {(img.betterThan >= 90 && img.impressions >= 50) &&
                            <img className="thumb-icon-gallery" src="/img/thumbs_up_003_small.png" alt="Thumbs up" />
                        }
                        <div className="hover-overlay" />
                        <div className="hover-icon">
                            <FontAwesomeIcon icon={faCircleInfo} size="2x" />
                        </div>
                        <EditImage open={openEditImage} handleClose={handleCloseEditImage} id={img.id} verdict={img.verdict} />
                    </div>
                ) : (
                    <div className="spinner-wrapper">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    </div>
                )}
                <div className="chart-wrapper">
                    {img.impressions >= 50 ? (
                        <div className="badge-container">
                            <div className="badge-button-container">
                                <img className="badge-button-image" src={`/img/icon_badge_round_001_${verdict.color}.png`} alt="Rating Icon" />
                                <div className="badge-button-text">{verdict.prefix}<br />{verdict.percentage}</div>
                            </div>
                            <div className="badge-badge-container">
                                <img className="badge-badge-image" src={`/img/icon_badge_rect_001_${verdict.color}.png`} alt="Badge Icon" />
                                <div className="badge-badge-text">{verdict.comment}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="badge-container">
                            <div className="badge-button-container">
                                <img className="badge-button-image" src="/img/icon_badge_round_001_grey.png" alt="Rating Icon" />
                                <div className="badge-button-text">??</div>
                            </div>
                            <div className="badge-badge-container">
                                <img className="badge-badge-image" src="/img/icon_badge_rect_001_grey.png" alt="Badge Icon" />
                                <div className="badge-badge-text">{"Not enough\nimpressions"}</div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="gallery-item-info">
                    <div className="info-row">
                        <span className="standout">Target: </span>
                        <span>{img.targetImpressions}</span>
                    </div>
                    <div className="info-row">
                        <span className="standout">Impressions: </span>
                        <span>{img.impressions}</span>
                    </div>
                    <div className="info-row">
                        <span className="standout">Clicks: </span>
                        <span>{img.clicks}</span>
                    </div>
                </div>
            </div >
        );
    } else return null;
};
export default Img;