import React, { useEffect, useState } from 'react'

function useImageName(initialName = "", setErrorMsg, setErrorIsWarning) {
    const [imgName, setImgName] = useState(initialName);

    useEffect(() => {
        setImgName(initialName);
    }, [initialName]);

    const onImgNameChanged = (e) => {
        const imageName = e.target.value;

        if (imageName.length > 100) {
            setErrorMsg('Image name may not be longer than 100 characters.');
            if (setErrorIsWarning)
                setErrorIsWarning(false);
            return;
        }

        if (imageName.trim() === "") {
            setErrorMsg('Image name may not be empty.');
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }
        else {
            setErrorMsg("")
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }

        setImgName(imageName);
    };

    const reset = () => {
        setErrorMsg("")
        if (setErrorIsWarning)
            setErrorIsWarning(false);
        setImgName(initialName);
    };

    return { imgName, setImgName, onImgNameChanged, reset };
}

export default useImageName