import awsConfig from '../config/awsConfig';

const { AWS_REGION, AWS_BUCKET, CLOUDFRONT_DOMAIN } = awsConfig;

export const USER_REGEX = /^[A-Za-z0-9_ '&-]{4,20}$/;
export const PWD_REGEX = /^[A-Za-z0-9!@#%_&-]{8,20}$/;

export function getImageURL(image) {
    const encodedInternalName = encodeURIComponent(image.internalname);

    //const imagePath = `https://${AWS_BUCKET}.s3.${AWS_REGION}.amazonaws.com/${image.folder}/${image.user}_${encodedInternalName}`;
    const imagePath = `https://${CLOUDFRONT_DOMAIN}/${image.folder}/${image.user}_${encodedInternalName}`;

    return imagePath;
}

// Zu Beginn der Website gibt es nur "Lower" (Prio 1) und "Higher" (Prio 1.3)
export const getQueuePrioritization = (plan) => {
    let prio = 1;
    if (plan === "Starter" || plan === "Lower" || plan === "AllPlans")
        prio = 1;
    else if (plan === "Influencer" || plan === "Higher")
        prio = 1.3;
    else if (plan === "Professional")
        prio = 1.6;
    else if (plan === "Premium")
        prio = 2.0;

    return prio;
}

export const getMaxImages = (plan) => {
    let maxImages = 6;
    if (plan === "Starter")
        maxImages = 12;
    else if (plan === "Influencer")
        maxImages = 40;
    else if (plan === "Professional")
        maxImages = 100;
    else if (plan === "Premium")
        maxImages = 300;

    return maxImages;
}

export const getMonthlyImpressions = (plan) => {
    let impressions = 0;
    if (plan === "Starter")
        impressions = 500;
    else if (plan === "Influencer")
        impressions = 2000;
    else if (plan === "Professional")
        impressions = 10000;
    else if (plan === "Premium")
        impressions = 50000;

    return impressions;
}

export const getRolloverCap = (plan) => {
    const impressions = getMonthlyImpressions(plan)
    return (impressions * 1.2)
}

export const getPrice = (plan) => {
    let price = 0;
    if (plan === "Starter")
        price = 749;    // 67 impressions per $ (500 impressions) 
    else if (plan === "Influencer")
        price = 2449;   // 82 impressions per $ (2000 impressions)
    else if (plan === "Professional")
        price = 9449;   // 106 impressions per $ (10000 impressions)
    else if (plan === "Premium")
        price = 29449;  // 170 impressions per $ (50000 impressions)

    return price;
}

export const isUpgrade = (currentPlan, newPlan) => {
    const plansOrder = ["Free", "Starter", "Influencer", "Professional", "Premium"];
    return plansOrder.indexOf(newPlan) > plansOrder.indexOf(currentPlan);
};

export const getVideoLinkAllowed = (plan) => {
    let allowed = false;
    if (plan === "Starter")
        allowed = true;
    else if (plan === "Influencer")
        allowed = true;
    else if (plan === "Professional")
        allowed = true;
    else if (plan === "Premium")
        allowed = true;

    return allowed;
}

export const getStripePriceID = (plan, isProduction) => {
    const devIDs = {
        "Starter": "price_1Nd9d0DmHWIqZD3jeeWjwAcs",
        "Influencer": "price_1Nd9dSDmHWIqZD3jxBLSpsWY",
        "Professional": "price_1Nd9e3DmHWIqZD3jwquDnHK7",
        "Premium": "price_1NdBRIDmHWIqZD3jWpG0h8Z9"
    };
    const prodIDs = {
        "Starter": "price_1OtqJXDmHWIqZD3jHhhvTGGG",
        "Influencer": "price_1OtqJrDmHWIqZD3jKwK0OUCq",
        "Professional": "price_1OtqK1DmHWIqZD3jzRJss4wr",
        "Premium": "price_1OtqKDDmHWIqZD3jiErg4G7C"
    };

    return (isProduction ? prodIDs[plan] : devIDs[plan]) || "";
}