import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditPasswordMutation, useSendLogoutMutation } from '../auth/authApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PWD_REGEX } from '../../utility/utilityFunctions';

const EditUserPassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordEdited, setPasswordEdited] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const [sendLogout] = useSendLogoutMutation();

    const [editPassword] = useEditPasswordMutation();

    const handleOldPasswordInput = (e) => setOldPassword(e.target.value);
    const handlePasswordInput = (e) => setPassword(e.target.value);
    const handleConfirmPasswordInput = (e) => setConfirmPassword(e.target.value);

    const handleLogout = async () => {
        try {
            await sendLogout().unwrap();
            navigate("/")
        } catch (error) {
            console.log('Error during logout: ', error);
        }
    }

    const handleEditPassword = async (e) => {
        e.preventDefault();
        if (!oldPassword) {
            setErrorMessage('Please enter your current password.');
            return;
        }

        if (!password || !confirmPassword) {
            setErrorMessage('Please fill in both password fields.');
            return;
        }

        if (!PWD_REGEX.test(password)) {
            setErrorMessage('Passwords must be 8-20 letters or numbers without spaces. Allowed special characters: !@#%_&-');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords must match.');
            return;
        }

        setErrorMessage("");
        setIsProcessing(true);

        try {
            const result = await editPassword({ password, currentPassword: oldPassword });
            if (!result.error) {
                // Reset successful
                setPasswordEdited(true);
                handleLogout();
            } else {
                setErrorMessage(result.error.data.message);
            }
        } catch (err) {
            setErrorMessage("There was an issue setting your new password. Please try again later.");
        }
        setIsProcessing(false);
    };

    const backToAccountDetails = () => {
        navigate("/dash/user")
    }

    return (
        <>
            <div className="darkish-box">
                <div className="standard-container-centered" style={{ paddingBottom: "0px" }}>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Change Password</h2>
                    </div>
                </div>
                <div className="standard-container-centered" style={{ marginTop: "0px", paddingTop: "30px" }}>
                    {!passwordEdited ? (
                        <form className="form-login" onSubmit={e => e.preventDefault()}>
                            <label htmlFor="password" className="form-login__label">Old Password:</label>
                            <input
                                className="form-login__input"
                                type="password"
                                id="oldPassword"
                                value={oldPassword}
                                onChange={handleOldPasswordInput}
                                autoComplete="off"
                                maxLength="20"
                                required
                            />

                            <label htmlFor="password" className="form-login__label">New Password:</label>
                            <input
                                className="form-login__input"
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordInput}
                                autoComplete="off"
                                maxLength="20"
                                required
                            />

                            <label htmlFor="confirmPassword" className="form-login__label">Confirm Password:</label>
                            <input
                                className="form-login__input"
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordInput}
                                autoComplete="off"
                                maxLength="20"
                                required
                            />

                            {isProcessing ? (
                                <div className="spinner-wrapper">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            ) : (
                                <div className="nav__buttons_bottom">
                                    <button className="button" onClick={backToAccountDetails} disabled={isProcessing}>Back</button>
                                    <button className="button" onClick={handleEditPassword} disabled={isProcessing}>Apply New Password</button>
                                </div>)}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </form>
                    ) : (
                        <div>
                            <p>Your password has been successfully changed.</p>
                            <div className="nav__buttons_bottom">
                                <button className="button" onClick={backToAccountDetails} disabled={isProcessing}>Account Details</button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="standard-container-centered" style={{ paddingTop: "0px" }}>
                    <div style={{ textAlign: 'center' }}>
                        You will get logged out after your password has been changed.<br></br>
                        Simply log back in with the new password afterwards.
                    </div>
                </div>
            </div >
        </>
    )
}

export default EditUserPassword;
