import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useVerifyEmailMutation } from '../auth/authApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const [verifyEmail] = useVerifyEmailMutation();

    useEffect(() => {
        const verifyUserEmail = async () => {
            setIsProcessing(true);
            try {
                const result = await verifyEmail(token);
                if (!result.error) {
                    // Verification successful
                    setIsVerified(true);
                } else {
                    setErrorMessage(result.error.data.message);
                }
            } catch (err) {
                setErrorMessage("There was an issue verifying your email. Please try again later.");
            }
            setIsProcessing(false);
        };
        verifyUserEmail();
    }, [token, verifyEmail]);

    const handleDashboardRedirect = () => {
        navigate('/dash/images');
    };

    return (
        <>
            <div className="standard-container-centered">
                {!isVerified && isProcessing && (
                    <div className="spinner-wrapper">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    </div>
                )}
                {!isVerified && !isProcessing && (
                    <div>
                        <p className="error-message">{errorMessage}</p>
                    </div>
                )}
                {isVerified && (
                    <div>
                        <p>Your email has been successfully verified. You can now use your account.</p>
                    </div>
                )}
            </div>
            <div className="nav__buttons_bottom">
                <button className="button" onClick={handleDashboardRedirect}>Dashboard</button>
            </div>
        </>
    )
}

export default VerifyEmail;
