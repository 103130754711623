import React, { useEffect, useState } from 'react'
import validator from 'validator'

function useVideoLink(initialLink = "", setErrorMsg, setErrorIsWarning) {
    const [videoLink, setVideoLink] = useState(initialLink);

    useEffect(() => {
        setVideoLink(initialLink);
    }, [initialLink]);

    const getYoutubeID = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : "";
    };

    const onVideoLinkChanged = (e) => {
        const url = e.target.value.trim();

        const validURL = validator.isURL(url);
        if (!validURL) {
            setErrorMsg('The video link must be empty or a Youtube link.');
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }

        const validID = getYoutubeID(url);
        if (!validID) {
            setErrorMsg('The video link must be empty or a Youtube link.');
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }

        if (url === "" || (validURL && validID)) {
            setErrorMsg("");
            if (setErrorIsWarning)
                setErrorIsWarning(false);
        }

        setVideoLink(url);
    };

    const reset = () => {
        setErrorMsg("");
        if (setErrorIsWarning)
            setErrorIsWarning(false);
        setVideoLink(initialLink);
    };

    return { videoLink, onVideoLinkChanged, reset };
}

export default useVideoLink
