import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faRedditAlien, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const url = encodeURIComponent('https://www.thumbrater.com');
    const shareMessage = 'Find out how good your Youtube thumbnail is before publishing the video...';
    const twitterShareLink = `https://twitter.com/intent/tweet?url=${url}&text=${shareMessage}`;
    const redditShareLink = `https://www.reddit.com/submit?url=${url}&title=${shareMessage}`;
    const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

    return (
        <footer className="footer">
            <div className="footer-links">
                <Link to="/termsOfService">Terms of Service</Link>
                <span>|</span>
                <Link to="/privacyPolicy">Privacy Policy</Link>
                <span>|</span>
                <Link to="/contactForm">Feedback & Contact</Link>
                <span>|</span>
                <a href={redditShareLink} target="_blank" rel="noopener noreferrer" title="Share on Reddit">
                    <FontAwesomeIcon icon={faRedditAlien} />
                </a>
                <a href={twitterShareLink} target="_blank" rel="noopener noreferrer" title="Share on X">
                    <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a href={facebookShareLink} target="_blank" rel="noopener noreferrer" title="Share on Facebook">
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <span>|</span>
                <span>&copy; {new Date().getFullYear()} ThumbRater.com</span>
            </div>
        </footer>
    );
};

export default Footer;