import React, { useState } from 'react';
import { useContactMutation } from '../features/users/usersApiSlice';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ContactForm = () => {
    const [inquiryType, setInquiryType] = useState('Support');
    const [message, setMessage] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [confirmation, setConfirmation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [contact] = useContactMutation();

    const handleInquiryTypeChange = (e) => setInquiryType(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);
    const handleEmailChange = (e) => setUserEmail(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validator.isEmail(userEmail)) {
            setErrorMessage('Invalid email format.');
            return;
        }

        setErrorMessage('');
        setIsSending(true);

        try {
            const result = await contact({ userEmail, inquiryType, message });

            if (!result.error) {
                setConfirmation('Thank you! Your inquiry has been sent.');
                setMessage('');
                setUserEmail('');
                setInquiryType('Support');
                setErrorMessage("");
            } else {
                setErrorMessage(result.error.data?.message);
            }
        } catch (error) {
            setErrorMessage("Something went wrong, please try again later.");
        }

        setIsSending(false);
    };

    return (
        <div className="standard-container-centered">
            <form className="form-login" onSubmit={handleSubmit}>
                <label htmlFor="inquiryType" className="form-login__label">Type of Inquiry</label>
                <select
                    className="form__select"
                    id="inquiryType"
                    value={inquiryType}
                    onChange={handleInquiryTypeChange}
                    required
                >
                    <option value="Support">Support</option>
                    <option value="Feedback">Feedback</option>
                    <option value="General">General</option>
                </select>

                <label htmlFor="email" className="form-login__label">Your Email</label>
                <input
                    className="form-login__input"
                    type="email"
                    id="email"
                    value={userEmail}
                    onChange={handleEmailChange}
                    required
                />

                <label htmlFor="message" className="form-login__label">Your Message</label>
                <textarea
                    className="form-login__input"
                    style={{ height: "300px", width: "420px", padding: "20px" }}
                    id="message"
                    value={message}
                    onChange={handleMessageChange}
                    maxLength="5000"
                    required
                />

                {isSending ? (
                    <div className="spinner-wrapper">
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    </div>
                ) : (
                    <button className="button" style={{ marginTop: "20px" }} disabled={isSending}>Send</button>
                )}
                {errorMessage && <p className="error-message" style={{ marginTop: "20px", marginBottom: "0px", paddingBottom: "0px" }}>{errorMessage}</p>}
                {confirmation && <p className="error-message" style={{ marginTop: "20px", marginBottom: "0px", paddingBottom: "0px", color: "white" }}>{confirmation}</p>}
            </form>
        </div>
    );
};

export default ContactForm;
