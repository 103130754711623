import React, { useEffect, useState, useContext } from 'react';
import { useGetImagesQuery } from "./imagesApiSlice";
import Img from "./Img";
import NewImage from './NewImage';
import { Link } from 'react-router-dom';
import { UserContext } from "../auth/ProtectedRoutes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import EmailVerification from '../auth/EmailVerification';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import { getMaxImages } from '../../utility/utilityFunctions';

const ImagesList = () => {
    const user = useContext(UserContext);
    const {
        username,
        email,
        plan,
        active: isActive
    } = user || {};

    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');
    const [showVideoTitle, setShowVideoTitle] = useState(true)
    const maxImages = getMaxImages(user.plan)

    const {
        data: images,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetImagesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [openNewImage, setOpenNewImage] = useState(false);

    const handleOpenNewImage = () => {
        if (images.ids.length < maxImages)
            setOpenNewImage(true)
        else {
            Swal.fire({
                title: 'Max Images Reached',
                html: 'You have reached the maximum number of images allowed for your subscription plan.<br/><br/>Please upgrade your plan in <b>Edit Account</b> or remove existing images.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Got it'
            })
        }
    };

    const handleCloseNewImage = () => {
        setOpenNewImage(false);
    }

    const handleSortChange = (event) => {
        const [field, order] = event.target.value.split('-');
        setSortField(field);
        setSortOrder(order);
    };

    const handleShowVideoTitle = (e) => {
        setShowVideoTitle(e.target.checked)
    }

    const getSortFunction = (field, order) => {
        const sortOrder = order === 'asc' ? 1 : -1;

        if (field === 'ctr') {
            return (a, b) => {
                const aValue = Number(a.impressions) !== 0 && Number(a.impressions) >= 50 ? Number(a.clicks) / Number(a.impressions) : 0;
                const bValue = Number(b.impressions) !== 0 && Number(b.impressions) >= 50 ? Number(b.clicks) / Number(b.impressions) : 0;
                return (aValue - bValue) * sortOrder;
            };
        }

        return (a, b) => {
            if (!isNaN(Date.parse(a[field])) && isNaN(Number(a[field]))) {
                const dateA = new Date(a[field]);
                const dateB = new Date(b[field]);
                return (dateA.getTime() - dateB.getTime()) * sortOrder;
            } else if (typeof a[field] === 'string') {
                return sortOrder === -1 ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
            } else {
                return (a[field] - b[field]) * sortOrder;
            }
        };
    };

    const getVerdict = (img) => {
        const betterThan = img.betterThan;

        if (img.impressions < 50)
            return { prefix: "??", percentage: "", raw: -1, color: "grey", comment: "Not enough\nimpressions" }

        if (betterThan < 10)
            return { prefix: "Bottom", percentage: "10%", color: "red", raw: betterThan, comment: "Critically\nLow" }
        else if (betterThan < 20)
            return { prefix: "Bottom", percentage: "20%", color: "red", raw: betterThan, comment: "Needs Major\nAttention" }
        else if (betterThan < 30)
            return { prefix: "Bottom", percentage: "30%", color: "red", raw: betterThan, comment: "Needs\nImprovement" }
        else if (betterThan < 50)
            return { prefix: "Bottom", percentage: "50%", color: "cyan", raw: betterThan, comment: "Below\nAverage" }
        else if (betterThan < 70)
            return { prefix: "Top", percentage: "50%", color: "cyan", raw: betterThan, comment: "Above\nAverage" }
        else if (betterThan < 80)
            return { prefix: "Top", percentage: "30%", color: "gold", raw: betterThan, comment: "Nice" }
        else if (betterThan < 90)
            return { prefix: "Top", percentage: "20%", color: "gold", raw: betterThan, comment: "Very Good" }
        else if (betterThan < 95)
            return { prefix: "Top", percentage: "10%", color: "gold", raw: betterThan, comment: "Outstanding" }
        else if (betterThan < 99)
            return { prefix: "Top", percentage: "5%", color: "gold", raw: betterThan, comment: "Mindblowing" }
        else
            return { prefix: "Top", percentage: "1%", color: "gold", raw: betterThan, comment: "Best of\nthe Best" }
    }

    let content;

    if (isLoading) content = <div className="spinner-wrapper">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
    </div>;

    if (isError) {
        let errorMessage = '';
        if (error.status === 'FETCH_ERROR' || !error.status) {
            errorMessage = 'The server is not responding. Please try again later.';
        } else {
            errorMessage = error?.data?.message || 'An unknown error occurred';
        }
        content = (
            <>
                <p className="error-message">{errorMessage}</p>
            </>
        );
    }

    //console.log("Images List, user:", user)
    //console.log("Images List, isActive:", isActive)

    if (isSuccess && isActive) {
        const imageObjects = images.ids.map(id => images.entities[id]);
        const imagesWithVerdicts = imageObjects.map(item => {
            return {
                ...item,
                verdict: getVerdict(item)
            }
        });
        const sortedImages = [...imagesWithVerdicts].sort(getSortFunction(sortField, sortOrder));

        content = (
            <>
                {images?.ids?.length && user.impressionsBudget < 1 ? <div className="gallery-container" style={{ flexDirection: "column" }}>
                    <p className="standout" style={{ textAlign: 'center', marginBottom: "0px", paddingBottom: "0px", color: "red" }}>No Impression Credits</p>
                    <p style={{ textAlign: 'center', fontSize: "92%", marginTop: "0px", paddingLeft: "7px", paddingRight: "7px", paddingTop: "0px" }}>On ThumbRater, users generate click data and receive click data for their own thumbnails in return.<br></br>Make sure to <span className="standout">visit the Rating page</span> to bring your Impression Credits above 0 - it's completely free and doesn't take much time.</p>
                    <div className="nav__buttons_bottom">
                        <Link to="/dash/rating" className="button">Rating</Link>
                        <button onClick={handleOpenNewImage} className="button">Upload Thumbnail</button>
                    </div>
                </div>
                    :
                    <div className="nav__buttons_bottom">
                        <button onClick={handleOpenNewImage} className="button">Upload Thumbnail</button>
                    </div>}
                {sortedImages.length ? <div className="gallery-above">
                    <div className="controls-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
                        <select
                            className="gallery-select"
                            value={`${sortField}-${sortOrder}`}
                            onChange={handleSortChange}>
                            <option value="createdAt-desc">Upload Date (newest first)</option>
                            <option value="createdAt-asc">Upload Date (oldest first)</option>
                            <option value="videotitle-desc">Video Title (A-Z)</option>
                            <option value="videotitle-asc">Video Title (Z-A)</option>
                            <option value="imgname-desc">Image Name (A-Z)</option>
                            <option value="imgname-asc">Image Name (Z-A)</option>
                            <option value="ctr-desc">Rating (highest first)</option>
                            <option value="ctr-asc">Rating (lowest first)</option>
                            <option value="impressions-desc">Impressions (highest first)</option>
                            <option value="impressions-asc">Impressions (lowest first)</option>
                            <option value="clicks-desc">Clicks (highest first)</option>
                            <option value="clicks-asc">Clicks (lowest first)</option>
                        </select>
                        <div className="form-editimage__subfield checkbox-container">
                            <input
                                id="active"
                                name="active"
                                type="checkbox"
                                className="checkbox-round"
                                style={{ width: "18px", height: "18px", minWidth: "18px", minHeight: "18px" }}
                                checked={showVideoTitle}
                                onChange={handleShowVideoTitle}
                            />
                            <Tooltip title="When enabled, uses the video title as each card's headline. When disabled, it uses the image name." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <span style={{ marginLeft: "2px", fontSize: "0.9em", width: "auto" }}>Video Title as Card Headline</span>
                            </Tooltip>
                        </div>
                    </div>
                    <Tooltip title="How many images you have online. You can increase the maximum by changing your subscription plan." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                        <span className="standout">Images: {sortedImages.length} / {maxImages}</span>
                    </Tooltip>
                </div> : null}
                <NewImage open={openNewImage} handleClose={handleCloseNewImage} user={user.id} />
                <div className="gallery-container">
                    {sortedImages.length ? sortedImages.map((image) => <Img key={image.id} img={image} videoTitleHeadline={showVideoTitle} />) : <p className="standout" style={{ textAlign: 'center' }}><br />You haven't uploaded any images yet.</p>}
                </div>
            </>
        );
    } else if (isSuccess && !isActive && username !== undefined) {
        content = <EmailVerification email={email} isActive={isActive} />;
        //content = null
    }

    return (
        <>
            {content}
        </>
    );
};

export default ImagesList;
