import { useState } from 'react';
import { useRetrieveUsernameMutation } from './usersApiSlice'
import { useSendResetTokenMutation } from '../auth/authApiSlice';
import { isEmail } from 'validator';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ForgotLoginData = () => {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [actionType, setActionType] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleEmailInput = (e) => setEmail(e.target.value);

    const [retrieveUsername] = useRetrieveUsernameMutation();
    const [sendResetToken] = useSendResetTokenMutation();

    const handleRetrieveUsername = async (e) => {
        e.preventDefault();

        if (!isEmail(email)) {
            setErrorMessage('Invalid email format. Please enter a valid email address.');
            return;
        }

        setErrorMessage("");
        setIsProcessing(true);

        try {
            const result = await retrieveUsername(email);

            if (!result.error) {
                setEmailSent(true);
                setActionType('retrieveUsername');
            }
            else
                setErrorMessage(result.error.data.message);
        }
        catch (err) {
            setErrorMessage("There was an issue sending your request. Please try again later.");
        }
        setIsProcessing(false);
    };

    const handleSendResetToken = async (e) => {
        e.preventDefault();
        if (!isEmail(email)) {
            setErrorMessage('Invalid email format. Please enter a valid email address.');
            return;
        }

        setErrorMessage("");
        setIsProcessing(true);

        try {
            const result = await sendResetToken(email);
            if (!result.error) {
                setEmailSent(true);
                setActionType('resetPassword');
            } else
                setErrorMessage(result.error.data.message);

        } catch (err) {
            setErrorMessage("There was an issue sending your reset token. Please try again later.");
        }
        setIsProcessing(false);
    };

    const content = (
        <>
            <div className="standard-container-centered">
                {!emailSent ? (

                    <form className="form-login" onSubmit={e => e.preventDefault()}>
                        <label htmlFor="email" className="form-login__label">Please input your account's email address:</label>
                        <input
                            className="form-login__input"
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailInput}
                            autoComplete="off"
                            required
                        />


                        {isProcessing ? (
                            <div className="spinner-wrapper">
                                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                            </div>
                        ) : (
                            <div className="nav__buttons_bottom">
                                <button className="button" onClick={handleRetrieveUsername} disabled={isProcessing}>Retrieve Username</button>
                                <button className="button" onClick={handleSendResetToken} disabled={isProcessing}>Reset Password</button>
                            </div>)}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </form>

                ) : (
                    <div>
                        <p>
                            {actionType === 'retrieveUsername'
                                ? "We've sent an email to the provided address with your username. Please check your inbox."
                                : "We've sent an email to the provided address with instructions to reset your password. Please check your inbox."}
                        </p>
                    </div>
                )}
            </div>
        </>
    )
    return content
}
export default ForgotLoginData
