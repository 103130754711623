import { apiSlice } from "../../app/api/apiSlice"

// Die API Endpoints, die wir hier definieren, korrespondieren nicht mit bestimmten Feldern im Redux State wie wir sie im Store definieren (siehe store.js: auth Feld). Stattdesssen arbeiten wir hier direkt mit dem RTK Query Cache
// Selbst erzeugte Felder wie auth sind eigentlich nur noetig fuer Daten, die wirklich unique sind. Daten, die einfach nur Kopien des Datenbankinhalts sind, wie z.B. alle Userdaten, benoetigen keine selbst erstellten Slices oder Reducers

// Base query is already inside our usersApiSlice
export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCurrentUser: builder.query({
            query: () => '/users/current',
            transformResponse: (responseData = {}) => {
                responseData.id = responseData._id || null;
                return responseData;
            },
            providesTags: (result, error, arg) => (
                result ? [{ type: 'User', id: 'current' }] : []
            )
        }),
        register: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [  // Tells the cache that these need to be updated now
                { type: 'User', id: "LIST" }
            ]
        }),
        deleteUser: builder.mutation({
            query: (password) => ({
                url: `/users`,
                method: 'DELETE',
                body: { currentPassword: password }
            }),
        }),
        retrieveUsername: builder.mutation({
            query: email => ({
                url: '/users/retrieveUsername',
                method: 'POST',
                body: { email },
            }),
        }),
        editEmail: builder.mutation({
            query: (data) => {
                //console.log("editEmail mutation, data:", data)
                return {
                    url: `/users/email`,
                    method: 'PATCH',
                    body: { email: data.email, currentPassword: data.currentPassword }
                };
            }
        }),
        contact: builder.mutation({
            query: (data) => {
                return {
                    url: `/users/contact`,
                    method: 'POST',
                    body: { inquiryType: data.inquiryType, message: data.message, userEmail: data.userEmail }
                };
            }
        }),
        startStripeSession: builder.mutation({
            query: data => ({
                url: '/sub/session',
                method: 'POST',
                body: { plan: data.plan },
            }),
        }),
        cancelSubscription: builder.mutation({
            query: data => ({
                url: '/sub/cancel',
                method: 'POST',
                body: { doCancel: data.doCancel }
            }),
        }),
        changeSubscription: builder.mutation({
            query: data => ({
                url: '/sub/change',
                method: 'POST',
                body: { plan: data.plan, immediate: data.immediate }
            }),
        })
    }),
})

// Hooks are automatically created
export const {
    useGetCurrentUserQuery,
    useRegisterMutation,
    useDeleteUserMutation,
    useRetrieveUsernameMutation,
    useEditEmailMutation,
    useStartStripeSessionMutation,
    useCancelSubscriptionMutation,
    useChangeSubscriptionMutation,
    useContactMutation
} = usersApiSlice