import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../auth/ProtectedRoutes";
import EmailVerification from '../auth/EmailVerification';
import Tooltip from '@mui/material/Tooltip';
import { getQueuePrioritization, getMonthlyImpressions, getMaxImages, getRolloverCap } from '../../utility/utilityFunctions';

const AccountDetails = () => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const {
        username,
        email,
        plan,
        renewalDate: renewalDateString,
        cancelDate: cancelDateString,
        changeDate: changeDateString,
        changePlan,
        active: isActive
    } = user || {};

    const premiumImpressions = getMonthlyImpressions(user.plan)
    const rolloverCap = getRolloverCap(user.plan)
    const maxImages = getMaxImages(user.plan)

    const environment = process.env.NODE_ENV || 'development';
    const customerPortal = environment === 'development'
        ? 'https://billing.stripe.com/p/login/test_9AQeW85uqg7L3V69AA'
        : 'https://billing.stripe.com/p/login/14kcP7g3m8xE5sk000';

    const editEmail = () => {
        navigate("/dash/user/editEmail");
    }

    const editPassword = () => {
        navigate("/dash/user/editPassword");
    }

    const deleteUser = () => {
        navigate("/dash/user/deleteUser");
    }

    const selectPlan = () => {
        navigate("/dash/user/selectPlan");
    }

    let content;

    let timeOptions = { hour: '2-digit', minute: '2-digit' };
    let renewalText = '';
    if (plan !== "Free") {
        if (cancelDateString) {
            let cancelDate = new Date(cancelDateString);
            renewalText = `, will end on ${cancelDate.toLocaleDateString()} (${cancelDate.toLocaleTimeString([], timeOptions)})`;
        }
        else if (changeDateString) {
            let changeDate = new Date(changeDateString);
            renewalText = `, will change to ${changePlan} on ${changeDate.toLocaleDateString()} (${changeDate.toLocaleTimeString([], timeOptions)})`;
        }
        else if (renewalDateString) {
            let renewalDate = new Date(renewalDateString);
            renewalText = `, renews on ${renewalDate.toLocaleDateString()} (${renewalDate.toLocaleTimeString([], timeOptions)})`;
        }
    }

    let changePlanText = plan === "Free" ? "Upgrade Plan" : "Change Plan";
    let credits = plan === "Free" ? "Earn by rating" : `+${premiumImpressions.toLocaleString()} each month (topup capped at ${rolloverCap.toLocaleString()})`
    let prio = getQueuePrioritization(plan)
    let priority = plan === "Free" ? "1.0x (no prioritization)" : `${prio}x (you get results ${Math.round((prio - 1.0) * 100)}% faster)`
    let links = plan === "Free" ? "No" : "Yes"

    content = (
        <>
            <div className="darkish-box">
                <h3 className="headline">Account Details</h3>
                <div className="account-details">
                    <div></div>
                    <p className="account-detail-line"><span className="standout">Username: </span> {username}</p>
                    <button className="account-button" onClick={editEmail}>Edit</button>
                    <p className="account-detail-line"><span className="standout">Email: </span> {email}</p>
                    <button className="account-button" onClick={editPassword}>Edit</button>
                    <p className="account-detail-line"><span className="standout">Password: </span> ********</p>
                </div>

                {isActive ? (
                    <>
                        <hr />

                        <h3 className="headline">Subscription Details</h3>

                        <div className="user-actions">
                            <button className="edit-account-link" style={{ display: "flex", textAlign: "center" }} onClick={selectPlan}>{changePlanText}</button>
                            {user.plan !== "Free" && (
                                /* eslint-disable-next-line react/jsx-no-target-blank */
                                <a href={customerPortal} target="_blank">
                                    <button className="edit-account-link" style={{ display: "flex", textAlign: "center" }}>Manage Subscription</button>
                                </a>
                            )}
                            <button className="edit-account-link" style={{ display: "flex", textAlign: "center" }} onClick={deleteUser}>Delete Account</button>
                        </div>

                        <div className="dark-box">
                            <Tooltip title="Your current subscription plan and its renewal date or end date." arrow placement="left" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <p className="account-sub-heading">Plan</p>
                            </Tooltip>
                            <p className="account-value">{plan}{renewalText}</p>

                            <Tooltip title="Impression Credits determine how often your thumbnails are shown to other users. Subscription plans award you a certain amount of impression credits each month." arrow placement="left" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <p className="account-sub-heading">Impression Credits</p>
                            </Tooltip>
                            <p className="account-value">{credits}</p>

                            <Tooltip title="Queue prioritization is automatically applied to all of your thumbnails. It gives thumbnails a higher chance of getting shown to other users on the Rating screen, which speeds up data gathering." arrow placement="left" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <p className="account-sub-heading">Queue Prioritization</p>
                            </Tooltip>
                            <p className="account-value">{priority}</p>

                            <Tooltip title="If you specify a Youtube video link on your thumbnail's detail page, other users get the option of watching the video after they voted for your thumbnail." arrow placement="left" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <p className="account-sub-heading">Video Links Allowed</p>
                            </Tooltip>
                            <p className="account-value">{links}</p>

                            <Tooltip title="How many thumbnails you can have online at the same time." arrow placement="left" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                                <p className="account-sub-heading">Max Images</p>
                            </Tooltip>
                            <p className="account-value">{maxImages}</p>
                        </div></>) :
                    null
                }
            </div>

            {!isActive && <EmailVerification email={email} isActive={isActive} />}
        </>
    );

    return (
        <>
            {content}
        </>
    );
};

export default AccountDetails;
