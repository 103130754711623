import { useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSendLogoutMutation } from "./authApiSlice";
import { UserContext } from "../auth/ProtectedRoutes";
import Tooltip from '@mui/material/Tooltip';

const UserInfo = () => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [sendLogout] = useSendLogoutMutation();

    const {
        username,
        plan,
        impressionsBudget: credits
    } = user || {};

    const handleLogout = async () => {
        try {
            await sendLogout().unwrap();
            navigate("/")
        } catch (error) {
            console.log('Error during logout: ', error);
        }
    }

    const isOnImagesPage = location.pathname === '/dash/images' || location.pathname === '/dash/rating';

    return (
        <div className="user-info">
            <p>
                <Tooltip title="Impression Credits determine how often your thumbnails are shown to other users. You can earn them by rating others' thumbnails or by joining one of our subscription plans." arrow placement="bottom" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <span className="standout">Impression Credits: </span>
                </Tooltip>{credits.toLocaleString()}
            </p>
            <p>
                <Tooltip title="Your user name." arrow placement="bottom" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <span className="standout">User: </span>
                </Tooltip>{username}
            </p>
            <p>
                <Tooltip title="Your subscription plan. You can change it in your account settings." arrow placement="bottom" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <span className="standout">Plan: </span>
                </Tooltip>{plan}
            </p>
            <div className="user-actions">
                <Link to={isOnImagesPage ? "/dash/user" : "/dash/images"} className="edit-account-link">
                    {isOnImagesPage ? 'Edit Account' : 'My Images'}
                </Link>
                <button className="edit-account-link" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    )
}

export default UserInfo