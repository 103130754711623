import Tooltip from '@mui/material/Tooltip';

function ImageDataForm(props) {
    const {
        imgName,
        videoTitle,
        videoLink,
        videoLinkAllowed,
        imgActive,
        impressions,
        targetImpressions,
        onImgNameChanged,
        onTitleChanged,
        onVideoLinkChanged,
        onImgActiveChanged,
        onTargetImpressionsChanged,
        headline
    } = props;

    const options = ["100", "200", "300", "500", "1000", "2000", "3000", "5000"].map((value) => {
        return (
            <option key={value} value={value}>
                {value}
            </option>
        );
    });

    return (
        <>
            <div className="form-login__title-row">
                <h2 className="modal__title">{headline}</h2>
            </div>

            <div className="form-editimage__field">
                <Tooltip title="The image name uniquely identifies this thumbnail. Useful if you want to test multiple different thumbnails for the same video." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <label className="form-editimage__label" htmlFor="imgName">
                        Image Name*
                    </label>
                </Tooltip>
                <input
                    className="form-editimage__input"
                    id="imgName"
                    name="imgName"
                    type="text"
                    autoComplete="off"
                    value={imgName}
                    onChange={onImgNameChanged}
                />
            </div>

            <div className="form-editimage__field">
                <Tooltip title="The title of the video this thumbnail is for. If left empty, the thumbnail will be shown alongside others that have no title. The title cannot be changed once the image has received impressions!" arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <label className="form-editimage__label" htmlFor="videotitle">
                        Video Title
                    </label>
                </Tooltip>
                <input
                    className="form-editimage__input"
                    id="videotitle"
                    name="videotitle"
                    type="text"
                    autoComplete="off"
                    value={videoTitle}
                    onChange={onTitleChanged}
                />
            </div>

            <div className="form-editimage__field">
                <Tooltip title="If you add a Youtube video link here, other users get the option of watching the video after they voted for your thumbnail." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                    <label
                        className="form-editimage__label" htmlFor="videolink"
                        style={{ color: videoLinkAllowed ? "var(--HEADLINE)" : "rgb(145, 110, 70)" }}>
                        Video Link
                    </label>
                </Tooltip>
                <input
                    className="form-editimage__input"
                    id="videolink"
                    name="videolink"
                    type="text"
                    autoComplete="off"
                    value={videoLink}
                    onChange={onVideoLinkChanged}
                    disabled={!videoLinkAllowed}
                    placeholder={!videoLinkAllowed ? "Video links not available for this plan" : ""}
                />
            </div>
            <p style={{ fontWeight: "500", marginTop: "-5px", marginLeft: "auto", marginRight: "5px", fontSize: "0.8em", color: "var(--HEADLINE)" }}>* required field</p>

            <div className="form-editimage__field">
                <div className="form-editimage__subfield">
                    <Tooltip title="If enabled, this image will gather data until its target impressions are reached." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                        <label className="form-login__label form-login__checkbox-container" htmlFor="active">Image Active
                        </label>
                    </Tooltip>
                    <input
                        id="active"
                        name="active"
                        type="checkbox"
                        className="checkbox-round"
                        checked={imgActive}
                        onChange={onImgActiveChanged}
                    />
                </div>


                <div className="form-editimage__subfield">
                    <Tooltip title="This value defines how often your thumbnail will be shown to other users." arrow placement="top" classes={{ tooltip: 'custom-tooltip', arrow: 'custom-tooltipArrow' }}>
                        <label className="form-login__label form-login__checkbox-container" htmlFor="targetimpressions">
                            Target Impressions
                        </label>
                    </Tooltip>
                    <select
                        id="targetimpressions"
                        name="targetimpressions"
                        className="form__select"
                        value={targetImpressions}
                        onChange={onTargetImpressionsChanged}
                    >
                        {options}
                    </select>
                </div>
            </div>

            <div className="img-details-container">
                <p className="img-details-left">{imgActive ? "Gathering data" : "Data gathering paused"}</p>
                <p className="img-details-right">{impressions >= 0 ? `Current impressions: ${impressions}` : null}</p>
            </div>

        </>
    )
}

export default ImageDataForm