import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div>
                <h1 className="policy-headline">Privacy Policy</h1>
                <p>Last updated: March 17, 2024</p>

                <h2 className="policy-headline">1. Introduction</h2>
                <p>Welcome to ThumbRater ("we", "us", or "our"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website ThumbRater.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site").</p>
                <p>Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Site.</p>

                <h2 className="policy-headline">2. Collection of Your Information</h2>
                <p>We may collect information about you in various ways:</p>
                <ul>
                    <li><strong>Personal Data:</strong> While using our service, we may ask you to provide us with certain personally identifiable information, such as your username and email address.</li>
                    <li><strong>Subscription Information:</strong> Payment information, such as credit card details, is collected and processed by Stripe, our payment processing partner. We do not store or handle this information on our website, except for the Stripe customer ID that's tied to your email.</li>
                </ul>

                <h2 className="policy-headline">3. Use of Your Information</h2>
                <p>We may use the information collected or provided by you for the following purposes:</p>
                <ul>
                    <li>Provide, maintain, and improve our service.</li>
                    <li>Process subscriptions and manage user accounts.</li>
                    <li>Notify you about changes to our service.</li>
                    <li>Respond to customer support requests.</li>
                </ul>

                <h2 className="policy-headline">4. Cookies and Tracking Technologies</h2>
                <p>
                    To provide a secure and seamless user experience, our Site utilizes HTTP-only cookies to keep users logged in through JWT. These cookies are essential for the functioning of the Site, and disabling cookies will prevent you from being able to use the Site, as it will not be possible to remain logged in without them.
                </p>
                <p>
                    By registering and agreeing to our Terms of Service and Privacy Policy, you acknowledge and accept the necessary use of these cookies for the site to function correctly.
                </p>

                <h2 className="policy-headline">5. Uploaded Content</h2>
                <p>
                    Users may upload images to our Site, stored on Amazon S3 servers, to be shown to other users. Images are distributed via our CloudFront content delivery network to enhance the site's performance and data security.
                </p>
                <p>
                    Please be aware that when images are deleted, they may remain temporarily accessible by calling their exact URL due to caching mechanisms. Although removed from our primary storage, these images might persist in caches for a period, which occurs automatically and is subject to cache expiration policies. We make efforts to ensure that caches are updated in a timely manner to prevent deleted images from being accessible beyond a reasonable timeframe.
                </p>

                <h2 className="policy-headline">6. Third-Party Services</h2>
                <p>We use third-party services such as Amazon S3 for storing images, Amazon CloudFront for distributing images and Stripe for processing payments. We recommend reading their Privacy Policies to understand how they manage your data.</p>

                <h2 className="policy-headline">7. Your Rights</h2>
                <p>You may cancel your subscription or delete your user account at any time. Upon deletion, your user data will be permanently deleted.</p>
                <p>If you delete your user account while you have an active subscription plan, the subscription will be immediately canceled.</p>

                <h2 className="policy-headline">8. Security</h2>
                <p>We strive to maintain the security of your personal information. We use encryption methods to protect your password and implement other security measures to protect your data.</p>

                <h2 className="policy-headline">9. Changes to this Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>


                <h2 className="policy-headline">10. Contact Us</h2>
                <p>For any questions or comments regarding this Privacy Policy, please contact us via the Feedback & Contact form at the bottom of this page.</p>
            </div>

            <div className="nav__buttons_bottom">
                <button onClick={goBack} className="button">Go Back</button>
            </div>
        </>
    );
}

export default PrivacyPolicy;
