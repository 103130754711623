import { useNavigate, Outlet } from "react-router-dom";
import { useContext, useState, useEffect, createContext } from 'react';
import { AuthContext } from "./AuthProvider";
import { useGetCurrentUserQuery } from "../users/usersApiSlice";
import { useSendLogoutMutation } from '../auth/authApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import UserInfo from "./UserInfo";
import Footer from "../../components/DashFooter";

export const UserContext = createContext();

// Erklaerunge fuer skip: !token und isLoadingToken. Ersteres ist notwendig, damit noch keine Userdaten geholt werden solange kein Token geladen ist (User waere sonst undefined, d.h. unauthorized). isLoadingToken wird verwendet, um sicherzugehen, dass kein navigate auf Login erfolgt solange der Token noch geladen wird

const ProtectedRoutes = ({ children }) => {
    const { token, isLoadingToken } = useContext(AuthContext);
    const { data: user, isLoading, isSuccess, isError, error } = useGetCurrentUserQuery(undefined, {
        refetchOnMountOrArgChange: true,
        skip: !token
    });

    const [logoutError, setLogoutError] = useState(null);
    const [sendLogout] = useSendLogoutMutation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await sendLogout().unwrap();
            navigate("/")
        } catch (error) {
            console.log('Error during logout: ', error);
            setLogoutError('There was an error logging out. Please try again later.');
        }
    }

    // Wenn User nicht eingeloggt ist: Auf Login-Seite leiten
    useEffect(() => {
        if (!isLoadingToken && !token) {
            navigate("/login");
        }
    }, [token, navigate, handleLogout, isLoadingToken]);

    if (!token || isLoading) {
        return (
            <div className="spinner-wrapper">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            </div>
        );
    }

    if (isError || logoutError) {
        return (
            <>
                <div className="standard-container-centered">
                    <div>Error: {error?.message || logoutError || 'A server error occurred.'}</div>
                </div>
                <div className="nav__buttons_bottom">
                    <button className="button" onClick={handleLogout}>Logout</button>
                </div>
            </>
        );
    }

    return (
        <UserContext.Provider value={user}>
            <UserInfo />
            <Outlet />
            <Footer />
        </UserContext.Provider>
    );
}

export default ProtectedRoutes;