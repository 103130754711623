import React from 'react';

const NotFound = () => {
    return (
        <div className="standard-container">
            <h2 className="headline">404 - Page Not Found</h2>
            <p className="standard-text" style={{textAlign: 'center'}}>The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;