import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAddNewImageMutation } from "./imagesApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faBan } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../auth/ProtectedRoutes";
import ImageDataForm from './ImageDataForm';
import useImageName from '../../hooks/useImageName';
import useVideoTitle from '../../hooks/useVideoTitle';
import useImageActive from '../../hooks/useImageActive';
import useVideoLink from '../../hooks/useVideoLink';
import useTargetImpressions from '../../hooks/useTargetImpressions';
import Swal from 'sweetalert2';
import { getVideoLinkAllowed } from '../../utility/utilityFunctions';

const NewImage = ({ open, handleClose, userID }) => {
    const user = useContext(UserContext)

    const [addNewImage,
        { isLoading, isError, error }
    ] = useAddNewImageMutation();

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImg, setPreviewImg] = useState("");
    const [errMsg, setErrMsg] = useState('');
    const [dragging, setDragging] = useState(false);
    const [errorIsWarning, setErrorIsWarning] = useState(false);
    const [targetImpressions, setTargetImpressions] = useState(200);
    const [imgActive, setImgActive] = useState(true);
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);

    const { imgName, setImgName, onImgNameChanged, reset: resetImgName } = useImageName("", setErrMsg, setErrorIsWarning);
    const { videoTitle, onTitleChanged, reset: resetVideoTitle } = useVideoTitle("", 0, setErrMsg, setErrorIsWarning);
    const { onImgActiveChanged } = useImageActive(setImgActive, true, 0, targetImpressions, setTargetImpressions);
    const { videoLink, onVideoLinkChanged, reset: resetVideoLink } = useVideoLink("", setErrMsg, setErrorIsWarning);
    const { onTargetImpressionsChanged } = useTargetImpressions(targetImpressions, setTargetImpressions, 0);

    useEffect(() => {
        if (open) {
            resetImgName();
            resetVideoTitle();
            resetImgActive();
            resetVideoLink();
            resetTargetImpressions();
            setSelectedFile(null);
            setPreviewImg("");
            setErrMsg("");
            setErrorIsWarning(false);
        }
    }, [open]);

    const showContentPolicy = () => {
        Swal.fire({
            title: 'Content Policy',
            html: `
                <p>When using our website, you agree to adhere to the following guidelines regarding image uploads:</p>
                <p><strong>No Nudity or Sexually Explicit Content</strong></p>
                <p><strong>No Extreme Violence or Gore</strong></p>
                <p>In general, your images and video titles should not contain profanity, threats, hate speech or promote violence and other illegal activities. While our guidelines cannot cover every possible scenario, we expect our users to exercise common sense.</p>
                <p>Failure to comply may result in the removal of content and/or suspension or termination of your account. We reserve the right to review and remove any content that violates these guidelines.</p>
            `,
            confirmButtonText: 'Okay'
        });
    };

    const resetTargetImpressions = () => {
        setTargetImpressions(200)
    }

    const resetImgActive = () => {
        setImgActive(true)
    }

    const resetForm = () => {
        resetImgName();
        resetVideoTitle();
        resetImgActive();
        resetVideoLink();
        resetTargetImpressions();
        setSelectedFile(null);
        setPreviewImg("");
        setErrMsg("");
        setErrorIsWarning(false);
        setIsUploadSuccessful(false);
    };

    const handleCloseAndReset = () => {
        handleClose();
        resetForm();
    };

    const onFileChanged = (file) => {
        if (!file)
            return

        const fileSize = file.size;
        if (fileSize > 4 * 1024 * 1024) {
            setErrorIsWarning(false);
            setSelectedFile(null);
            resetImgName();
            setPreviewImg("");
            // Erst am Ende setzen, weil der resetImgName Hook die errMsg auf "" setzt!
            setErrMsg("The selected image is larger than 4 MB. Please select an image with a smaller file size.");
            return;
        }

        if (file && ["image/jpeg", "image/png"].includes(file.type)) {
            setSelectedFile(file);

            // Removes the file extension from the file name
            const fileName = file.name.replace(/\.[^/.]+$/, "");
            //setImgName(user.username + "_" + file.name);
            const clippedFileName = fileName.length > 100 ? fileName.substring(0, 100) : fileName;
            setImgName(clippedFileName);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImg(reader.result);

                // Create an image to get the width and height to calculate the aspect ratio
                const img = new Image();
                img.onload = function () {
                    const aspectRatio = this.width / this.height;
                    if (aspectRatio < 1.77 || aspectRatio > 1.78) {
                        setErrMsg("Note: Your image will be displayed distorted because its aspect ratio is not 16:9. We recommend you use a resolution such as 1280x720 or 1920x1080.")
                        setErrorIsWarning(true);
                    } else {
                        setErrorIsWarning(false);
                        setErrMsg("");
                    }
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
            resetImgName();
            setPreviewImg("");
            setErrMsg("Only JPG and PNG are allowed.")
        }
    };

    const onSaveImageClicked = async (e) => {
        e.preventDefault();
        if (canSave && selectedFile) {
            const initialImage = {
                user: userID,
                imgname: imgName,
                videotitle: videoTitle.trim() !== "" ? videoTitle : "N/A",
                image: selectedFile,
                videoLink: videoLink,
                active: imgActive,
                targetImpressions: targetImpressions
            };

            const result = await addNewImage(initialImage);

            // Check if the upload was successful
            if (!!result?.data?.success) {
                //console.log("NewImage: Upload isSuccess. Closing dialog.")
                setIsUploadSuccessful(true);
                handleCloseAndReset();
            }
        }
    };

    const handleFileInput = (e) => {
        onFileChanged(e.target.files[0]);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!dragging)
            setDragging(true);
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onFileChanged(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    const errClass = isError ? "errmsg" : "offscreen";
    const canSave = Boolean(imgName) && Boolean(selectedFile) && !isLoading && (!errMsg || !!errorIsWarning);
    const videoLinkAllowed = getVideoLinkAllowed(user.plan)

    return (
        <Dialog open={open} onClose={handleCloseAndReset} PaperProps={{ className: 'MuiDialog-paper' }}>
            <>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    className="MuiIconButton-root"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
                <DialogContent className="muidialog-content">
                    {isLoading || isUploadSuccessful ? (
                        <div className="spinner-wrapper">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        </div>
                    ) : (
                        <>
                            <p className={errClass}>{error?.data?.message}</p>

                            <form className="form-editimage" onSubmit={onSaveImageClicked}>
                                <ImageDataForm
                                    headline="Image Details"
                                    imgName={imgName}
                                    videoTitle={videoTitle}
                                    videoLink={videoLink}
                                    videoLinkAllowed={videoLinkAllowed}
                                    imgActive={imgActive}
                                    impressions={-1}
                                    targetImpressions={targetImpressions}
                                    onImgNameChanged={onImgNameChanged}
                                    onTitleChanged={onTitleChanged}
                                    onVideoLinkChanged={onVideoLinkChanged}
                                    onImgActiveChanged={onImgActiveChanged}
                                    onTargetImpressionsChanged={onTargetImpressionsChanged}
                                />

                                <label className="button" htmlFor="image">
                                    Select Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    onChange={handleFileInput}
                                    style={{ display: 'none' }}
                                />

                                <div
                                    onDragEnter={handleDragIn}
                                    onDragLeave={handleDragOut}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                >
                                    {previewImg ? (
                                        <div className="detail-chart-wrapper">
                                            <div style={{ position: 'relative' }}>
                                                <img
                                                    src={previewImg}
                                                    className={`bordered-imageupload ${dragging ? 'dragging-border' : ''}`}
                                                    alt="Selected"
                                                />
                                                {!imgActive &&
                                                    <div style={{ position: 'relative' }}>
                                                        <FontAwesomeIcon
                                                            icon={faBan}
                                                            className="ban-icon"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`placeholder-box ${dragging ? 'dragging-border' : ''}`}>
                                            <span>Select or drag/drop an image</span>
                                        </div>
                                    )}
                                </div>

                                {errMsg && <p className="errmsg">{errMsg}</p>}

                                <div style={{ marginBottom: "8px" }}>
                                    <span style={{ color: "white", fontSize: "0.9em" }}>Remember to follow ThumbRater's <span onClick={showContentPolicy} className="textlink">content policy.</span></span>
                                </div>

                                <button
                                    className={`button ${!canSave && "disabled"}`}
                                    title="Save"
                                    disabled={!canSave}
                                    type="submit"
                                >Upload
                                </button>

                            </form>
                        </>
                    )}
                </DialogContent>
            </>
        </Dialog>
    );
};

export default NewImage;