import { Routes, Route } from 'react-router-dom'
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Layout from './components/Layout'
import Public from './components/Public'
import FAQ from "./components/FAQ";
import Login from './components/Login';
import ForgotLoginData from './features/users/ForgotLoginData';
import ImagesList from './features/images/ImagesList'
import Register from './components/Register';
import ScrollToTop from './components/ScrollToTop';
import ResetPassword from './features/users/ResetPassword';
import VerifyEmail from './features/users/VerifyEmail';
import ProtectedRoutes from './features/auth/ProtectedRoutes';
import NotFound from './components/NotFound';
import { AuthProvider } from './features/auth/AuthProvider';
import AccountDetails from './features/users/AccountDetails';
import Rating from './features/images/Rating.js';
import EditUserEmail from './features/users/EditUserEmail';
import EditUserPassword from './features/auth/EditUserPassword';
import DeleteUser from './features/users/DeleteUser';
import SelectPlan from './features/users/SelectPlan';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsofService from './components/TermsofService';
import PageWrapper from './components/PageWrapper';
import ContactForm from './components/ContactForm';

// AuthProvider muss wrappen, weil die Kinder zugriff darauf brauchen. ScrollToTop ist quasi eigenstaendig und muss nicht wrappen
function App() {
    const location = useLocation();

    return (
        <div>
            <ScrollToTop />
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Outlet />}>
                        <Route element={<Layout />}>
                            <Route element={<PageWrapper />}>
                                <Route index element={<Public />} />
                                <Route path="login" element={<Login />} />
                                <Route path="register" element={<Register />} />
                                <Route path="faq" element={<FAQ />} />
                                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                                <Route path="termsOfService" element={<TermsofService />} />
                                <Route path="contactForm" element={<ContactForm />} />
                                <Route path="forgotlogin" element={<ForgotLoginData />} />
                                <Route path="reset-password/:token" element={<ResetPassword />} />
                                <Route path="verify-email/:token" element={<VerifyEmail />} />
                            </Route>

                            <Route path="dash" element={<ProtectedRoutes />}>
                                <Route path="user">
                                    <Route index element={<AccountDetails />} />
                                    <Route path="editEmail" element={<EditUserEmail />} />
                                    <Route path="editPassword" element={<EditUserPassword />} />
                                    <Route path="selectPlan" element={<SelectPlan />} />
                                    <Route path="deleteUser" element={<DeleteUser />} />
                                </Route>
                                <Route path="images">
                                    <Route index element={<ImagesList />} />
                                </Route>
                                <Route path="rating">
                                    <Route index element={<Rating />} />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Route>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;