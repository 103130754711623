import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function LoadingDialog({ open, handleCloseAndReset }) {
    return (
        <Dialog open={open} onClose={(e) => {
            e.stopPropagation();
            handleCloseAndReset();
        }} PaperProps={{ className: 'MuiDialog-paper' }}>
            <DialogContent className="muidialog-content">
                <div className="spinner-wrapper">
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default LoadingDialog;