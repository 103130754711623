import { createSlice } from '@reduxjs/toolkit'

// Beachten: Die action.payload sind hier unterschiedlich zw. setCredentials und setEmailVerificationError: ersteres erwartet ein Objekt mit accessToken Property, letzteres setzt den payload direkt!
const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            state.token = accessToken
        },
        logOut: (state, action) => {
            state.token = null
        },
        setEmailVerificationError: (state, action) => {
            state.emailVerificationError = action.payload;
        },
    }
})

export const { setCredentials, logOut, setEmailVerificationError } = authSlice.actions

// Gibt den aktuellen Token zurueck
export const getCurrentToken = (state) => state.auth.token

export default authSlice